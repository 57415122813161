import React, { useCallback, useContext, useMemo, useState } from 'react'
import './header.css'
import MenuIcon from '@mui/icons-material/Menu';
import DatePicker from './DatePicker/DatePicker';
import { MainViews } from '../../MainViews';
import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from '@mui/material';
import HelpDialog from '../HelpDialogs/HelpDialog';
import HelpSummary from '../../HelpContents/HelpSummary';
import HelpMoneyIn from '../../HelpContents/HelpMoneyIn';
import HelpContentExpenses from '../../HelpContents/HelpContentExpenses';
import HelpContentStats from '../../HelpContents/HelpContentStats';
import HelpContentSettings from '../../HelpContents/HelpContentSettings';

function Header({ toggleDrawer = () => { }, setMonth = () => { }, selectedMonth, setYear = () => { }, selectedYear, years = [], selectedMainView }) {

  const [showHelp, setShowHelp] = useState(false);


  const HelpContent = useCallback(() => {
    switch (selectedMainView) {
      case MainViews.SUMMARY:
        return <HelpSummary />;
      case MainViews.MONEYIN:
        return <HelpMoneyIn />
      case MainViews.EXPENSES:
        return <HelpContentExpenses />
      case MainViews.STATS:
        return <HelpContentStats />
      case MainViews.SETTINGS:
        return <HelpContentSettings />
      default:
        return <></>;
    }
  }, [selectedMainView])
  return (
    <>
      <div className='header-container'>
        <MenuIcon className='header-hamburger-menu' onClick={toggleDrawer} fontSize='large' />
        <div className='header-date-picker'>
          <div><DatePicker value={selectedMonth} setValue={setMonth} /></div>
          <div><DatePicker value={selectedYear} setValue={setYear} isYear={true} years={years} /></div>
        </div>
        <IconButton
          onClick={() => setShowHelp(true)}
        >
          <HelpIcon
            className='icon-size'
          />
        </IconButton>
      </div>
      <HelpDialog
        showHelp={showHelp}
        onClose={() => setShowHelp(false)}
      >
        <HelpContent />
      </HelpDialog>
    </>

  )
}

export default Header