import savingSimulation from "../../../../assets/stats/risparmio_probabile.png"
import HelpContentStyledPar from "../HelpContentsStyledPar"
import wantedSaving from "../../../../assets/stats/saldo_desiderato.png"
import wantedSavingNotPossible from "../../../../assets/stats/saldo_desiderato_non_possibile.png"

export default function HelpContentStatsSimulation() {
    return (
        <>
            <h3>Risparmio Probabile</h3>
            <p>
                Permette di calcolare la capacità di risparmio, sulla base del saldo medio, nel numero di mesi indicato.
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={savingSimulation}
                />
            </HelpContentStyledPar>
            <h3>Saldo Desiderato</h3>
            <p>
                Necessità l'inserimento di due informazioni:
                <ul>
                    <li>Numero di mesi da considerare</li>
                    <li>Importo desiderato</li>
                </ul>
                Lo strumento calcolerà quale deve essere il saldo medio mensile e verificherà se, con i dati in possesso dell'applicazione, è possibile raggiungere l'obbiettivo.
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={wantedSaving}
                />
            </HelpContentStyledPar>
            <p>
                Nel caso in cui non fosse possibile raggiungere l'obbiettivo, sarà possibile accedere ai dettagli avanzati per poter capire da quale categoria di spesa è possibile ricavare un risparmio maggiore.
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={wantedSavingNotPossible}
                />
            </HelpContentStyledPar>
            
        </>
    )
}