import { makeStyles } from "@mui/material";
import { toast } from "react-toastify";

export function successNotification(text = "") {
    toast.success(text, {
        autoClose : 2000
    })
}

export function errorNotification(text = "") {
    toast.error(text, {
        autoClose : 2000
    })
}

export function toggleLoadingOverlay() {
    const overlay = document.querySelector(".loading-overlay");
    overlay.classList.toggle("hide");
    overlay.classList.toggle("show");
}

export const authorizationPath = "auth.txt"

export function buildDate(date) {
    let month = date.getMonth()+1;
    let day = date.getDate();

    if (month < 10) {
        month = `0${month}`;
    }

    if (day < 10) {
        day = `0${day}`;
    }

    return `${date.getFullYear()}-${month}-${day}`;
} 

export function getTextFieldStyle() {
    const size = window.innerWidth;
}

export function validatePassword(password) {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;
    return passwordRegex.test(password)
}

export default function toMoney(amount){
    return parseFloat(amount).toLocaleString("it-IT", { style: "currency", currency: "EUR" })
}