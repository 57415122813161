import HelpContentStyledPar from "../HelpContentsStyledPar";
import setMonthlyBudget from "../../../../assets/expenses/manage_budget/pulsante_gestione_budget.png";
import monthlyBudgetForm from "../../../../assets/expenses/manage_budget/form_modifica_budget_mensile.png";
import SettingsIcon from '@mui/icons-material/Settings';

export default function HelpContentExpensesManageBudget() {
    return (
        <>
            <p>
                E' possibile modificare il budget di default (impostato precedentemente dalla sezione <b>Impostazioni</b>) per il mese in questione accedendo alla finestra 
                <b> Budget Mensile</b> tramite il pulsante pulsante <SettingsIcon />
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={setMonthlyBudget}
                />
            </HelpContentStyledPar>
            <p>
                Sarà sufficiente inserire l'importo desiderato e cliccare il pulsante <b>Modifica</b>
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={monthlyBudgetForm}
                />
            </HelpContentStyledPar>
            <p>
                <b>N.B.</b> la modifica avrà effetto solo per il mese selezionato. Per modificare il budget definitivamente è necessario recarsi nella sezione <b>Impostazioni</b>
            </p>
        </>
    )
}