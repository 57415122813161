import { useCallback, useContext, useMemo, useState } from "react"
import "./moneyin.css"
import { AppContext } from "../../../../Context/AppContext"
import { Dialog, DialogActions, DialogContent, DialogTitle, Fab, SpeedDialIcon, Typography } from "@mui/material"
import EditMoneyInDialog from "./Components/EditMoneyInDialog"
import { MoneyInAPI } from "../../../../RemoteAPI/MoneyIn"
import { errorNotification, successNotification, toggleLoadingOverlay } from "../../../../utils"
import MoneyInItem from "./Components/MoneyInItem"

export default function MoneyIn({ }) {

    const [openAddDialog, setOpenAddDialog] = useState(false)

    const AppData = useContext(AppContext)

    const total = useMemo(() => {
        return AppData.moneyIn.value.reduce((sum, moneyIn) => sum += moneyIn.amount, 0)
    }, [AppData.moneyIn.value])

    const onAddDialogOpen = useCallback(() => {
        setOpenAddDialog(true)
    }, [setOpenAddDialog])

    const onAddDialogClose = useCallback(() => {
        setOpenAddDialog(false)
    }, [setOpenAddDialog])

    const onAddMoneyIn = useCallback(async (body = { description: "", amount: "" }) => {
        toggleLoadingOverlay()
        try {
            const result = await MoneyInAPI.post.addMoneyIn(body.description, body.amount === "" ? 0 : parseFloat(body.amount),
                AppData.selectedMonth.value + 1,
                AppData.selectedYear.value, AppData.authentication.value.token)
            const response = await result.json();
            if (result.status === 201) {
                successNotification(response.result.description)
                AppData.moneyIn.refresh()
                AppData.stats.refreshStats();
            }
            else {
                errorNotification(response.result.description)
            }
        } catch (error) {
            errorNotification("Errore del server.")
            toggleLoadingOverlay()
        }
        toggleLoadingOverlay()
    }, [AppData])

    return (
        <>
            {
                AppData.moneyIn.value.length === 0 &&
                <>
                    <div
                        className="categories-empty-container"
                    >
                        <div className="summary-money-in-total-container">
                            <h2>Nessuna entrata inserita</h2>
                            <h3>Inseriscile per poter generare statistiche coerenti con la tua situazione finanziaria.</h3>
                        </div>
                    </div>
                </>
            }
            {AppData.moneyIn.value.length > 0 &&
                <div className="money-in-container">
                    <div className="money-in-total shadows">
                        <Typography fontSize={"1.5rem"}>
                            Totale : {total.toLocaleString("it-IT", { style: "currency", currency: "EUR" })}
                        </Typography>
                    </div>
                    <div className="money-in-total-items-container shadows">
                        {
                            AppData.moneyIn.value.map((moneyIn, index) => (
                                <MoneyInItem key={`money-in-item-${index}`} item={moneyIn} token={AppData.authentication.value.token} refreshData={() => { AppData.moneyIn.refresh(); AppData.stats.refreshStats() }} />
                            ))
                        }
                    </div>

                </div>
            }


            <Fab
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                color="primary"
                size="large"
                onClick={onAddDialogOpen}
            >
                <SpeedDialIcon />
            </Fab>
            <EditMoneyInDialog
                open={openAddDialog}
                onClose={onAddDialogClose}
                onSave={onAddMoneyIn}
            />
        </>
    )
}