import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export default function HelpDialog({children, showHelp, onClose}) {
    return (
        <Dialog
            open={showHelp}
            onClose={onClose}
            className="help-dialog"
            maxWidth="lg"
        >
            <DialogTitle
                className="help-dialog-title"
            >
                Aiuto
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={onClose}
                    className="button"
                >
                    Chiudi
                </Button>
            </DialogActions>
        </Dialog>
    )
}