import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import HelpDialog from "../../../../Components/HelpDialogs/HelpDialog";
import StatsDetailsCategoriesItem from "./StatsDetailsCategoriesItem";
import { StatsDetailTypes } from "../StatsDetails";

export default function StatsDetailsCriticalCategories({ categories, type }) {

    const [showHelp, setShowHelp] = useState(false);

    const title = useMemo(() => {
        switch (type) {
            case StatsDetailTypes.CRITICAL:
                return "Peggiori"
            case StatsDetailTypes.WARNING:
                return "Da Monitorare"
            case StatsDetailTypes.HINT:
                return "Senza Budget"
            case StatsDetailTypes.GOOD:
                return "Migliori"
            default:
                break;
        }
    }, [type])

    const HelpContent = useCallback(() => {
        if (type === StatsDetailTypes.CRITICAL) {
            return (
                <>
                    In queste categorie di spesa il bilancio tra spesa attesa (sommatoria dei budget) e spesa effettiva è negativo.
                    <p
                        style={{
                            fontWeight: "bold"
                        }}
                    >
                        Ciò significa che nella maggior parte dei mesi non è stato rispettato il budget definito.
                    </p>
                    <p>
                        Queste categorie sono ordinate per la differenza percentuale che c'è tra spesa attesa e spesa effettiva.
                        Questo da indicazione su quali sono le categorie in cui si tende a rispettare meno il budget.
                    </p>
                </>
            )
        }

        if (type === StatsDetailTypes.WARNING) {
            return (
                <>
                    In queste categorie di spesa il bilancio tra spesa attesa (sommatoria dei budget) e spesa effettiva è positivo,
                    ma, sporadicamente, il budget è stato superato.
                    <p
                        style={{
                            fontWeight: "bold"
                        }}
                    >
                        Non occorre concetrarsi troppo sull'eventuale risparmio dato da queste categorie perché generalmente il superamento del loro budget
                        è dovuto ad eventi sporadici.
                    </p>
                    <p>
                        Queste categorie sono ordinate secondo il numero di volte in cui il budget viene superato.
                    </p>
                </>
            )
        }

        if (type === StatsDetailTypes.HINT) {
            return (
                <>
                    Per queste categorie non è stato impostato alcun budget.
                    <p
                        style={{
                            fontWeight: "bold"
                        }}
                    >
                        Non è possibile fornire consigli coerenti sulle possibilità di risparmio
                    </p>
                </>
            )
        }

        if (type === StatsDetailTypes.GOOD) {
            return (
                <>
                    In queste categorie non è mai stato superato il budget durante l'anno. Quindi hanno generato un risparmio maggiore delle aspettative.
                    <p
                        style={{
                            fontWeight: "bold"
                        }}
                    >
                        Non è possibile fornire ulteriori consigli possibilità di risparmio perché si è già raggiunto il miglior risultato possibile.
                    </p>
                </>
            )
        }

        return (<></>)

    }, [type])

    return (
        <>
            <div
                className="summary-money-in-total-container"
                style={{
                    marginBottom: "2vh"
                }}
            >
                <div className="summary-money-in-total-title">

                    Categorie {title}
                    <IconButton
                        onClick={() => setShowHelp(true)}
                    >
                        <HelpCenterIcon className='icon-size' />
                    </IconButton>
                </div>
                {categories.length === 0 && <div style={{fontSize : "xx-large", fontWeight : "bold", textAlign : "center", width : "100%"}}>Nessuna</div>}
                {categories.map(category => <StatsDetailsCategoriesItem category={category} type={type} />)}
            </div>
            <HelpDialog
                onClose={() => setShowHelp(false)}
                showHelp={showHelp}
            >
                <HelpContent />
            </HelpDialog>

        </>

    )
}