import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { SummaryAPI } from "../../../../../RemoteAPI/Summary";
import { AppContext } from "../../../../../Context/AppContext";
import StatsDetailsCriticalCategories from "./StatsDetailsComponents/StatsDetailsCriticalCategories";
import PennyPincherSpinner from "../../../../PennyPincherSpinner/PennyPincherSpinner";

export const StatsDetailTypes = {
    CRITICAL: "critical",
    WARNING: "warning",
    HINT: "hint",
    GOOD: "good"
}

export default function StatsDetails({ open, onClose }) {

    const [detailsData, setDetailsData] = useState(null);

    const AppData = useContext(AppContext);

    useEffect(() => {
        if (open) {
            SummaryAPI.get.getStatsDetails(AppData.authentication.value.token, AppData.selectedYear.value)
                .then(async response => {
                    console.log(response);
                    const result = await response.json();
                    setDetailsData(result.data);
                })
        }
    }, [open])

    const onCloseWrapper = useCallback(() => {
        onClose();

        const timeoutId = setTimeout(() => {
            setDetailsData(null)
            clearTimeout(timeoutId);
        }, [500])
    }, [])

    return (
        <Dialog
            className="help-dialog"
            open={open}
            onClose={onCloseWrapper}
            fullScreen
        >
            <DialogTitle
                className="help-dialog-title"
            >
                Analisi Spese
            </DialogTitle>
            <DialogContent
                sx={detailsData === null ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#4169e1bf"
                } : {
                    background: "#4169e1bf",
                    paddingTop: "1vh !important"
                }}
            >
                {detailsData === null && <PennyPincherSpinner />}
                {detailsData !== null &&
                    <>
                        <StatsDetailsCriticalCategories categories={detailsData.critical} type={StatsDetailTypes.CRITICAL} />
                        <StatsDetailsCriticalCategories categories={detailsData.warning} type={StatsDetailTypes.WARNING} />
                        <StatsDetailsCriticalCategories categories={detailsData.hint} type={StatsDetailTypes.HINT} />
                        <StatsDetailsCriticalCategories categories={detailsData.good} type={StatsDetailTypes.GOOD} />
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    className="button"
                    onClick={onCloseWrapper}
                    variant="contained"
                >
                    Chiudi
                </Button>
            </DialogActions>
        </Dialog>
    )
}