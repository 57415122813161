import { useCallback, useContext, useEffect, useState } from "react"
import { CategoryModel } from "../../../../../../Models/CategoryModel"
import { ExpensesCategoriesAPI } from "../../../../../../RemoteAPI/ExpensesCategories"
import { AppContext } from "../../../../../../Context/AppContext"
import { errorNotification, successNotification, toggleLoadingOverlay } from "../../../../../../utils"
import CategoryItem from "./CategoryItem"
import CategoryEditDialog from "./Dialogs/CategoryEditDialog"
import { Fab, SpeedDialIcon } from "@mui/material"
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CategoryEditPositionDialog from "./Dialogs/CategoryEditPositionDialog"
import PennyPincherSpinner from "../../../../../PennyPincherSpinner/PennyPincherSpinner"


export default function Categories({ }) {

    const [isLoading, setIsLoading] = useState(true);

    const [expensesCategories, setExpensesCategories] = useState([])

    const [newCategoryDialogShow, setNewCategoryDialogShow] = useState(false)

    const [editCategoryPositionDialogShow, setEditCategoryPositionDialogShow] = useState(false)

    const AppData = useContext(AppContext)


    const getExpensesCategories = useCallback(async () => {

        setIsLoading(true)
        /* toggleLoadingOverlay() */
        try {
            const result = await ExpensesCategoriesAPI.get.getExpensesCategories(AppData.authentication.value.token)
            const response = await result.json()
            setExpensesCategories(response.data)
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            errorNotification("Errore del server");
            /* toggleLoadingOverlay() */
        }


        /* toggleLoadingOverlay() */

    }, [setExpensesCategories, AppData.authentication.value.token])

    const createEpenseCategory = useCallback((body) => {
        ExpensesCategoriesAPI.post.createExpensesCategories(AppData.authentication.value.token, body)
            .then(async result => {
                const response = await result.json();
                if (result.status !== 201) {
                    errorNotification(response.result.description);
                    return;
                }
                successNotification(response.result.description);
                setNewCategoryDialogShow(false);
                getExpensesCategories();
                AppData.expenses.refresh();
            })
            .catch((error) => {
                console.log(error);
                errorNotification("Errore del server.");
            })
    }, [AppData.authentication.value.token, getExpensesCategories, setNewCategoryDialogShow])

    useEffect(() => {
        getExpensesCategories()
    }, [])

    console.log(expensesCategories);

    const reorder = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    return (
        <>

            {isLoading &&
                <div
                    className="categories-loading-container"
                >
                    <PennyPincherSpinner />
                </div>
            }
            {!isLoading && expensesCategories.length === 0 &&
                <div
                    className="categories-empty-container"
                >
                    <div className="summary-money-in-total-container">

                        <h2>Nessuna Categoria inserita</h2>
                        <h3>Inserisci almeno una categoria per poter registrare le spese</h3>

                    </div>
                </div>
            }
            {expensesCategories.length > 0 &&
                <div className="categories-container">
                    {expensesCategories.map(cateogory => <CategoryItem
                        categoryExpense={cateogory}
                        token={AppData.authentication.value.token}
                        getExpensesCategories={getExpensesCategories}
                        refreshExepenses={AppData.expenses.refresh}
                    />)
                    }
                </div>
            }

            <Fab
                sx={{ position: 'absolute', bottom: 70, right: 16 }}
                color="primary"
                size="large"
                onClick={() => setNewCategoryDialogShow(true)}
            >
                <SpeedDialIcon />
            </Fab>
            {expensesCategories.length > 1 &&
                <Fab
                    sx={{ position: 'absolute', bottom: 70, left: 16 }}
                    color="primary"
                    size="large"
                    onClick={() => setEditCategoryPositionDialogShow(true)}
                >
                    <FormatListNumberedIcon />
                </Fab>
            }
            {
                newCategoryDialogShow &&
                <CategoryEditDialog
                    onClose={() => setNewCategoryDialogShow(false)}
                    open={newCategoryDialogShow}
                    onDelete={() => { }}
                    onSave={createEpenseCategory}
                    token={AppData.authentication.value.token}
                />
            }


            <CategoryEditPositionDialog
                open={editCategoryPositionDialogShow}
                onClose={() => setEditCategoryPositionDialogShow(false)}
                categories={expensesCategories}
                token={AppData.authentication.value.token}
                refreshCategories={getExpensesCategories}
            />

        </>
    )
}