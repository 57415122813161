import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppContextProvider from './Context/AppContext';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ScaleLoader } from 'react-spinners';
import { ErrorBoundary } from 'react-error-boundary';
import Errors from './Views/Errors/Errors';
import PennyPincherSpinner from './Views/PennyPincherSpinner/PennyPincherSpinner';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={Errors}
      onError={(error) => {
        console.log(error.cause, error.message, error.name)
      }}
    >
      <AppContextProvider>
        <App />
        <ToastContainer />
        <div className='loading-overlay hide'>
          <PennyPincherSpinner />
        </div>
      </AppContextProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
