import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import HelpContentExpensesFirst from "./HelpContentExpensesTabs/HelpContentExpensesFirst";
import HelpContentExpensesManageBudget from "./HelpContentExpensesTabs/HelpContentExpensesManageBudget";
import HelpContentExpensesAddExpense from "./HelpContentExpensesTabs/HelpContentExpensesAddExpense";
import HelpContentExpensesRecurrentExpenses from "./HelpContentExpensesTabs/HelpContentExpensesRecurrentExpenses";
import HelpContentExpensesEditExpense from "./HelpContentExpensesTabs/HelpContentExpensesEditExpense";



export default function HelpContentExpenses() {
    const [tabValue, setTabValue] = useState(0)
    return (
        <>
            <Tabs
                value={tabValue}
                onChange={(e, newValue) => setTabValue(newValue)}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{
                    padding: 0
                }}
            >
                <Tab label="Uscite" />
                <Tab label="Gestione Budget Categoria" />
                <Tab label="Inserimento Spese" />
                <Tab label="Spese Ricorrenti" />
                <Tab label="Gestione Spese" />
            </Tabs>
            <div
                style={{
                    overflowY: "auto",
                    height: "70vh",
                    padding : "0 1vw"
                }}
            >
                {tabValue === 0 && <HelpContentExpensesFirst />}
                {tabValue === 1 && <HelpContentExpensesManageBudget />}
                {tabValue === 2 && <HelpContentExpensesAddExpense />}
                {tabValue === 3 && <HelpContentExpensesRecurrentExpenses />}
                {tabValue === 4 && <HelpContentExpensesEditExpense />}
            </div>
        </>
    )
}