import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { useMemo, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpDialog from "../../../../Components/HelpDialogs/HelpDialog";
import { StatsDetailTypes } from "../StatsDetails";

const toMoney = (amount) => {
    return parseFloat(amount).toLocaleString("it-IT", { style: "currency", currency: "EUR" })
}

const toPercentage = (value) => {
    return parseFloat(value).toFixed(1);
}

export default function StatsDetailsCategoriesItem({ category, type }) {

    const [showDetails, setShowDetails] = useState(false);
    const [showSuggestionHelp, setShowSuggestionHelp] = useState(false)

    console.log(category);

    const expectedAmount = useMemo(() => {
        return parseFloat(category.expected_amount)
    }, [category])

    const negativeBalanceOccurencePercentage = useMemo(() => {
        return toPercentage(((parseInt(category.negative_balance_occurrence) / parseInt(category.occurrence)) * 100))
    }, [category])

    const isCritical = useMemo(() => {
        return type === StatsDetailTypes.CRITICAL
    }, [])

    return (
        <>
            <div
                className="stats-details-critical-categories-item"
            >
                <div
                    style={{
                        width: "45%"
                    }}
                >
                    {category.expense_category_description}
                </div>
                <div
                    style={{
                        width: isCritical ? "30%" : "50%",
                        textAlign: "right"
                    }}
                >
                    {parseFloat(category.negative_balance_total).toLocaleString("it-IT", { style: "currency", currency: "EUR" })}
                </div>
                {isCritical &&
                    <div
                        style={{
                            width: "20%",
                            textAlign: "right"
                        }}
                    >
                        {toPercentage(category.balance_displacement)}%
                    </div>
                }
                <div>
                    <IconButton
                        onClick={() => setShowDetails(true)}
                    >
                        <InfoIcon className='icon-size' />
                    </IconButton>
                </div>
            </div>
            <Dialog
                className="help-dialog"
                open={showDetails}
                fullScreen
            >
                <DialogTitle
                    className="help-dialog-title"
                >
                    <IconButton className='icon-size' onClick={() => setShowDetails(false)} ><ArrowBackIcon /></IconButton> Dettagli Categoria <span style={{ textDecoration: "underline" }} >{category.expense_category_description}</span>
                </DialogTitle>
                <DialogContent
                    sx={{
                        background: "#4169e1bf",
                        paddingTop: "1vh !important"
                    }}
                >
                    <div
                        className="summary-money-in-total-container"
                        style={{
                            paddingBottom: "0.5vh"
                        }}
                    >
                        <div className="summary-money-in-total-title" style={{ marginBottom: "1vh" }}>
                            Informazioni
                        </div>
                        <div className="stats-details-critical-categories-item-info-row">
                            <div className="">
                                <div className="stats-details-critical-categories-item-info-title">Spesa attesa</div>
                                <div className="stats-details-critical-categories-item-info-content number">{toMoney(expectedAmount)}</div>
                            </div>
                            <div className="">
                                <div className="stats-details-critical-categories-item-info-title">Spesa effettiva</div>
                                <div className="stats-details-critical-categories-item-info-content number">{toMoney(category.effective_amount)}</div>
                            </div>

                            <div className="">
                                <div className="stats-details-critical-categories-item-info-title">% di superamento</div>
                                <div className="stats-details-critical-categories-item-info-content number">{isCritical ? `${toPercentage(category.balance_displacement)}%` : "N/D" }</div>
                            </div>

                        </div>
                        <div className="stats-details-critical-categories-item-info-row">
                            <div className="">
                                <div className="stats-details-critical-categories-item-info-title">% mesi superamento</div>
                                <div className="stats-details-critical-categories-item-info-content number">{negativeBalanceOccurencePercentage}%</div>
                            </div>
                            <div className="">
                                <div className="stats-details-critical-categories-item-info-title">N. superamenti</div>
                                <div className="stats-details-critical-categories-item-info-content number">{category.negative_balance_occurrence}</div>
                            </div>
                            <div className="">
                                <div className="stats-details-critical-categories-item-info-title">Peso sul totale</div>
                                <div className="stats-details-critical-categories-item-info-content number">{toPercentage(category.weight_on_total)}%</div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="summary-money-in-total-container"
                        style={{
                            paddingBottom: "0.5vh",
                            marginTop: "2vh"
                        }}
                    >
                        <div className="summary-money-in-total-title" style={{ marginBottom: "1vh" }}>
                            Dettaglio Mensile
                        </div>
                        <div
                            style={{
                                height: "50vh",
                                overflowY: "auto"
                            }}
                        >
                            {category.budget_details_by_months
                                .map(det => {
                                    return (
                                        <div
                                            style={{
                                                borderBottom: "1px solid lightgray"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginBottom: "0.5vh",
                                                    fontSize: "large",
                                                    fontWeight: "bold"
                                                }}
                                            >
                                                {det.month}
                                            </div>
                                            <div className="stats-details-critical-categories-item-info-row">
                                                <div className="">
                                                    <div className="stats-details-critical-categories-item-info-title">Budget</div>
                                                    <div className="stats-details-critical-categories-item-info-content number">{toMoney(det.budget)}</div>
                                                </div>
                                                <div className="">
                                                    <div className="stats-details-critical-categories-item-info-title">Spesa</div>
                                                    <div className="stats-details-critical-categories-item-info-content number">{toMoney(det.budget + (det.balance < 0 ? Math.abs(det.balance) : -det.balance))}</div>
                                                </div>
                                                <div className="">
                                                    <div className="stats-details-critical-categories-item-info-title">Saldo</div>
                                                    <div style={{color : det.balance < 0 ? "red" : "black"}} className="stats-details-critical-categories-item-info-content number">{toMoney(det.balance)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                    <div
                        className="summary-money-in-total-container"
                        style={{
                            paddingBottom: "0.5vh",
                            marginTop: "2vh"
                        }}
                    >
                        <div className="summary-money-in-total-title" style={{ marginBottom: "1vh" }}>
                            Consigli
                            <IconButton
                                onClick={() => setShowSuggestionHelp(true)}
                            >
                                <InfoIcon className='icon-size' />
                            </IconButton>
                        </div>
                        <div className="stats-details-critical-categories-item-info-row">
                            <div className="">
                                <div className="stats-details-critical-categories-item-info-title">Risparmio mensile aggiuntivo</div>
                                <div className="stats-details-critical-categories-item-info-content number">{toMoney(category.potential_monthly_saving)}</div>
                            </div>
                            <div className="">
                                <div className="stats-details-critical-categories-item-info-title">Risparmio annuo</div>
                                <div className="stats-details-critical-categories-item-info-content number">{toMoney(category.potential_monthly_saving * 12)}</div>
                            </div>
                        </div>
                        <HelpDialog
                            showHelp={showSuggestionHelp}
                            onClose={() => setShowSuggestionHelp(false)}
                        >
                            <p>Se i budget impostati venissero rispettati ogni mese, sarebbe possibile risparmiare: <span style={{ fontWeight: "bold" }}>{toMoney(category.potential_monthly_saving)}</span></p>

                            <p>Ovvero un risparmio aggiuntivo di <span style={{ fontWeight: "bold" }}>{toMoney(category.potential_monthly_saving * 12)}</span> annui</p>

                            {category.negative_balance_occurrence > 6 &&
                                <p>
                                    Hai superato il budget <span style={{fontWeight : "bold"}}>{category.negative_balance_occurrence}</span> volte, nel caso in cui non sia possibile tagliare le spese si consiglia di adeguare il budget.
                                </p>
                            }
                        </HelpDialog>
                    </div>
                </DialogContent>
            </Dialog>
        </>

    )
}