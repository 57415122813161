
import { useCallback, useContext, useMemo } from "react";
import "./expenses.css";
import { useState } from "react";
import { ExpensesAPI } from "../../../../RemoteAPI/Expenses";
import { AppContext } from "../../../../Context/AppContext";
import ExpenseItem from "./Components/ExpenseItem";


export default function Expenses({ }) {
    const AppData = useContext(AppContext);

    return (
        <>
            {AppData.expenses.value.length === 0 &&
                <div
                    className="categories-empty-container"
                >
                    <div className="summary-money-in-total-container">
                        <h2>Nessuna Categoria inserita</h2>
                        <h3>Inserisci almeno una categoria per poter registrare le spese</h3>
                    </div>
                </div>
            }
            {AppData.expenses.value.length > 0 &&
                <div className="expenses-container shadows">
                    {AppData.expenses.value.map(category => <ExpenseItem
                        category={category}
                        colors={AppData.colors.value}
                        token={AppData.authentication.value.token}
                        refMonth={AppData.selectedMonth.value + 1}
                        refYear={AppData.selectedYear.value}
                        refreshExpenses={AppData.expenses.refresh}
                    />)}
                </div>
            }
        </>

    )
}