import { ExpenseModel } from "../../../../../Models/ExpenseModel";
import NotesIcon from '@mui/icons-material/Notes';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useCallback, useContext, useRef, useState } from "react";
import { AppContext } from "../../../../../Context/AppContext";
import { buildDate, errorNotification, successNotification, toggleLoadingOverlay } from "../../../../../utils";
import { ExpensesAPI } from "../../../../../RemoteAPI/Expenses";
import AddExpenseDialog from "../Dialogs/AddExpenseDialog";

export default function ExpenseItemDetail({ expense = { ...ExpenseModel } }) {

    const [openDescriptionDialog, setOpenAddExpensedialog] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)

    const AppData = useContext(AppContext);

    const switchRef = useRef(null);

    const closeDeleteDialog = useCallback(() => {
        setOpenDeleteDialog(false)
    }, [setOpenDeleteDialog])

    const deleteExpense = useCallback(async () => {
        toggleLoadingOverlay()
        try {
            let deleteRecurrents = false 
            if (expense.expense_id_recurrent_parent !== null) {
                deleteRecurrents = switchRef.current.firstChild.checked
            }
            const result = await ExpensesAPI.delete.deleteExpense(AppData.authentication.value.token, expense.expense_id, deleteRecurrents);

            const response = await result.json();

            if (result.status === 200) {
                successNotification(response.result.description);
                AppData.expenses.refresh();
                if (deleteRecurrents) {
                    AppData.authentication.refreshYears()
                }
                closeDeleteDialog()
            }
            else {
                errorNotification(response.result.description);
            }
            toggleLoadingOverlay()
        } catch (error) {
            console.log(error)
            errorNotification("Errore del server.");
            toggleLoadingOverlay()
        }
    }, [expense, AppData.authentication.value, closeDeleteDialog, switchRef])



    return (
        <>
            <div
                className="expense-item-detail-container"
            >
                <div
                    className="expense-item-detail-title"
                >
                    <div
                        style={{
                            background: "white",
                            border: "1px solid lightgray",
                            borderRadius: "5px",
                            padding: "0 2vw",
                            background: "lightcyan"
                        }}
                    >
                        <div
                            style={{
                                fontWeight: "bold"
                            }}
                        >
                            {expense.title}
                        </div>
                        <div>{expense.date}</div>
                    </div>



                    <div
                        className="expense-item-detail-price"
                    >
                        {expense.amount.toLocaleString("it-IT", { style: "currency", currency: "EUR" })}
                    </div>
                </div>


                <div
                    className="expense-item-detail-actions"
                >
                    <IconButton
                        onClick={() => setOpenAddExpensedialog(true)}
                    >
                        <NotesIcon className='icon-size' />
                    </IconButton>
                    <IconButton
                        onClick={() => setOpenEditDialog(true)}
                    >
                        <EditIcon className='icon-size' />
                    </IconButton>
                    <IconButton
                        onClick={() => setOpenDeleteDialog(true)}
                    >
                        <DeleteIcon className='icon-size' />
                    </IconButton>
                </div>
            </div>

            <Dialog
                className="help-dialog"
                open={openDescriptionDialog}
                onClose={() => setOpenAddExpensedialog(false)}
                fullWidth
            >
                <DialogTitle
                    className="help-dialog-title"
                >
                    Descrizione
                </DialogTitle>
                <DialogContent>
                    {expense.description === "" ? "Nessuna descrizione inserita" : expense.description}
                </DialogContent>
            </Dialog>

            <AddExpenseDialog
                expenseCategoryId={expense.expense_category_id}
                expense={expense}
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
            />

            <Dialog
                className="help-dialog"
                open={openDeleteDialog}
                onClose={closeDeleteDialog}
                fullWidth
            >
                <DialogTitle
                    className="help-dialog-title"
                >
                    Eliminazione
                </DialogTitle>
                <DialogContent>
                    Sei sicuro di voler eliminare la spesa: <b>{expense.title}</b> del <b>{buildDate(new Date(expense.date))}</b>?
                    {expense.expense_id_recurrent_parent &&
                        <>
                            <p>
                                Vuoi eliminare anche le spese ricorrenti per i mesi successivi?
                            </p>
                            <span>NO</span><Switch ref={switchRef} /><span>SI</span>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={closeDeleteDialog} className="button">Annulla</Button>
                    <Button variant="contained" color="warning" onClick={deleteExpense} className="button">Elimina</Button>
                </DialogActions>
            </Dialog>
        </>

    )
}