import { API_URL } from "./config"

function getPostPutHeaders(token) {
    return {
        "Authorization": `Bearer ${token}`,
        "Content-Type" : "application/json"
    }
}

function redMoneyIn(refYear, refMonth, token) {
    const headers = {
        "Authorization" : `Bearer ${token}`
    }
    
    return fetch(`${API_URL}/moneyin/year/${refYear}/month/${refMonth}`, {
        method: "GET",
        headers : headers
    })
}

function addMoneyIn(description, amount, refMonth, refYear, token) {
    const body = {
        description: description,
        amount: amount,
        ref_month : refMonth,
        ref_year : refYear
    }

    const headers = getPostPutHeaders(token)

    return fetch(`${API_URL}/moneyin`, {
        method: "POST",
        headers: headers,
        body : JSON.stringify(body)
    })
}

function updateMoneyIn(moneyInId, description, amount, token) {
    const body = {
        description,
        amount
    }

    const headers = getPostPutHeaders(token)

    return fetch(`${API_URL}/moneyin/${moneyInId}`, {
        method: "PUT",
        headers: headers,
        body : JSON.stringify(body)
    })
}

function deleteMoneyIn(moneyInId, token) {
    const headers = getPostPutHeaders(token);

    return fetch(`${API_URL}/moneyin/${moneyInId}`, {
        method: "DELETE",
        headers : headers
    })
}

export const MoneyInAPI = {
    get: {
        redMoneyIn
    },
    post: {
        addMoneyIn
    },
    put: {
        updateMoneyIn
    },
    delete: {
        deleteMoneyIn
    }
}