export const CategoryModel = {
    expense_category_id: 0,
    description: "",
    user_id: 0,
    weekly_view: 0,
    color: {
        color_id: 0,
        hex_code: ""
    },
    icon_id: 0,
    default_budget: 0,
    position : 0
}