import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ExpenseModel } from "../../../../../Models/ExpenseModel";
import { useCallback, useMemo, useState } from "react";
import AddExpenseDialog from "./AddExpenseDialog";
import ExpenseItemDetail from "../Components/ExpenseItemDetail";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import toMoney from "../../../../../utils";

const ExpenseGroupings = {
    PLAIN: "plain",
    DATE: "date"
}

export default function ExpensesListDialog({ open, onClose, categoryName, categoryId, expenseList = [{ ...ExpenseModel }] }) {

    const [openAddExpenseDialog, setOpenAddExpensedialog] = useState(false);

    const [selectedExpenseGrouping, setSelectedExpenseGrouping] = useState(ExpenseGroupings.PLAIN)


    const expenses = useMemo(() => {
        if (expenseList.length === 1 && expenseList[0].expense_id === 0) {
            return [];
        }

        const expenseByDateMap = new Map();
        expenseList.forEach(expense => {
            const date = expense.date;
            if (!expenseByDateMap.has(date)) {
                expenseByDateMap.set(date, {
                    date: date,
                    expenses: []
                })
            }
            expenseByDateMap.get(date).expenses.push(expense)
        })

        const expenseByDateArray = [...expenseByDateMap].map(([key, value]) => value)
        /* return expenseList */
        return {
            expenseByDateArray,
            expenseList
        }
    }, [expenseList])

    const totalAmount = useMemo(() => {
        return expenseList.reduce((sum, item) => sum += item.amount, 0);
    }, [expenseList])

    return (
        <>
            <Dialog
                className="help-dialog"
                open={open}
                onClose={onClose}
                maxWidth={false}
                fullWidth
            >
                <DialogTitle
                    className="help-dialog-title"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    {categoryName} <Button className="button" variant="contained" color="primary" onClick={() => setOpenAddExpensedialog(true)}>Aggiungi</Button>
                </DialogTitle>
                <DialogContent
                    sx={{ padding: 0 }}
                >
                    <div
                        className="expenses-list-container"
                    >
                        {expenses.expenseByDateArray.length === 0 && expenses.expenseList.length === 0 && <div
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
                        >
                            <h2>Nessuna spesa inserita</h2>
                        </div>}

                        {(expenses.expenseByDateArray.length > 0 || expenses.expenseList.length > 0) &&
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: "1vh",
                                    paddingBottom: selectedExpenseGrouping === ExpenseGroupings.PLAIN ? "0.2vh" : "1vh"
                                }}
                            >
                                {selectedExpenseGrouping === ExpenseGroupings.PLAIN &&
                                    <Button
                                        className="button"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setSelectedExpenseGrouping(ExpenseGroupings.DATE)}
                                    >
                                        Raggruppa per data
                                    </Button>
                                }
                                {selectedExpenseGrouping === ExpenseGroupings.DATE &&
                                    <Button
                                        className="button"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setSelectedExpenseGrouping(ExpenseGroupings.PLAIN)}
                                    >
                                        Lista
                                    </Button>
                                }
                            </div>
                        }

                        {selectedExpenseGrouping === ExpenseGroupings.PLAIN && expenses.expenseList.map(expense => <ExpenseItemDetail expense={expense} />)}

                        {selectedExpenseGrouping === ExpenseGroupings.DATE && expenses.expenseByDateArray.map(expenseByDate => {
                            return (
                                <Accordion
                                    sx={{
                                        background: "#e1e1ff"
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "95%"
                                            }}
                                        >
                                            <span>{expenseByDate.date}</span>
                                            <b>{expenseByDate.expenses.reduce((sum, value) => sum += value.amount, 0).toLocaleString("it-IT", { style: "currency", currency: "EUR" })}</b>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {expenseByDate.expenses.map(expense => <ExpenseItemDetail expense={expense} />)}
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </div>
                    <div className="expenses-list-total-amount">{toMoney(totalAmount)}</div>
                </DialogContent>
            </Dialog>

            <AddExpenseDialog
                open={openAddExpenseDialog}
                onClose={() => setOpenAddExpensedialog(false)}
                expenseCategoryId={categoryId}
            />
        </>

    )
}