import { useState } from "react"
import "./splash.css"
import { Slide } from "@mui/material"
import PennyPincherSpinner from "../PennyPincherSpinner/PennyPincherSpinner"

export default function Splash({ }) {

    const [showPenny, setShowPenny] = useState(true)
    const [showPincher, setShowPincher] = useState(true)

    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "2vw",
                flexDirection: "column"
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Slide
                    direction="right"
                    in={showPenny}
                    mountOnEnter
                    unmountOnExit
                    timeout={850}
                >
                    <div
                        style={{
                            position: "relative",
                            left: "6vw"
                        }}
                    >
                        <span className="splash-capital">
                            P
                        </span>
                        <span className="splash-text">
                            enny
                        </span>
                    </div>
                </Slide>
                <Slide
                    direction="left"
                    in={showPincher}
                    mountOnEnter
                    unmountOnExit
                    timeout={850}
                >
                    <div>
                        <span className="splash-capital">
                            P
                        </span>
                        <span className="splash-text">
                            incher
                        </span>
                    </div>
                </Slide>
            </div>
            <Slide
                direction="up"
                in={true}
                mountOnEnter
                unmountOnExit
                style={{
                    transitionDelay : 500
                }}
                timeout={850}
            >
                <div>
                    <PennyPincherSpinner />
                </div>

            </Slide>

        </div>
    )
}