import HelpContentStyledPar from "./HelpContentsStyledPar";
import detailsButton from "../../../assets/stats/detailed_stats.png"
import balanceChart from "../../../assets/moneyin/andamento_saldo.png"
import addRealClosing from "../../../assets/summary/mensile-reale.png";
import monthClosingChart from "../../../assets/stats/grafico_andamento_chiusure.png";
import simulationIcon from "../../../assets/stats/icona_strumento_simulazione.png";
import CalculateIcon from '@mui/icons-material/Calculate';
import HelpContentStatsSimulation from "./HelpContentStatsSimulation/HelpContentStatsSimulation";

export default function HelpContentStats() {
    return (
        <>
            <p>
                La sezione <b>Statistiche</b> permette di ottenere informazioni inerenti alla situazione globale dell'<b>anno</b> in questione.
                E' possibile visionare un'analisi dettagliata di tutte le spese tramite l'apposito pulsante:
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={detailsButton}
                />
            </HelpContentStyledPar>
            <h3>Andamento Saldo</h3>
            <p>
                Il grafico <b>Andamento Saldo</b> permette di tenere d'occhio la capacità di risparmio per tutti i mesi dell'anno. <br />
                I dati che alimentano tale grafico sono ricavti dalle informazioni inserite nella sezione <b>Entrate</b> e <b>Uscite</b>
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={balanceChart}
                />
            </HelpContentStyledPar>
            <h3>Andamento Chiusure Mensili</h3>
            <p>
                Il grafico <b>Andamento Chiusure Mensili</b> permette di tenere analizzare la salute economica globale. <br />
                Ricava i suoi dati dalle informazioni inserite nella sezione <b>Riepilogo</b>. L'informazione richiesta saldo reale a disposizione a termine del mese.
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={addRealClosing}
                />
            </HelpContentStyledPar>
            <p>
                Permette inoltre di verificare se l'andamento globale è migliorato o peggiorato.
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={monthClosingChart}
                />
            </HelpContentStyledPar>
            <h3>Strumento di simulazione</h3>
            <p>
                Accessibile tramite il pulsante <CalculateIcon />, mette a disposizione alcuni semplici strumenti per generare alcune simulazioni.
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={simulationIcon}
                />
            </HelpContentStyledPar>
            <HelpContentStatsSimulation />
        </>
    )
}