import { useCallback, useMemo, useState } from "react";
import { CategoryModel } from "../../../../../Models/CategoryModel";
import { ExpenseModel } from "../../../../../Models/ExpenseModel";
import { Icons } from "../../../../../Models/Icons";
import { ColorModel } from "../../../../../Models/ColorModel";
import { Button, IconButton } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import SetExpenseCategoryBudgetDialog from "../Dialogs/SetExpenseCategoryBudgetDialog";
import { errorNotification, successNotification } from "../../../../../utils";
import { ExpensesCategoriesAPI } from "../../../../../RemoteAPI/ExpensesCategories";
import ExpensesListDialog from "../Dialogs/ExpensesListDialog";


export default function ExpenseItem({
    category = { ...CategoryModel, expenses: [ExpenseModel] },
    colors = [ColorModel], token, refMonth, refYear, refreshExpenses
}) {

    const [openBudgetDialog, setOpenBudgetDialog] = useState(false);

    const [openExpenseListDialog, setOpenExpenseListDialog] = useState(false)

    const Icon = useMemo(() => {
        return Icons[category.icon_id];
    }, [category])

    const color = useMemo(() => {
        return colors.find(c => c.color_id === category.color_id);
    }, [category, colors])


    const expenseTotal = useMemo(() => {
        return category.expenses.reduce((sum, item) => sum += item.amount, 0)
    }, [category])


    console.log(category);

    const setBudget = useCallback(async (budget) => {
        let status = 0;
        try {
            const body = {
                budget: budget,
                ref_month: refMonth,
                ref_year : refYear
            }
            const result = await ExpensesCategoriesAPI.post.setMonthlyBudget(token, category.expense_category_id, body)
            status = result.status;
            const response = await result.json();
            
            if (result.status !== 200) {
                errorNotification(response.result.description)
            }
            else {
                successNotification(response.result.description)
                refreshExpenses();
                setOpenBudgetDialog(false);
            }
        } catch (error) {
            console.log(error);
            if (status === 200) {
                errorNotification("Errore del server. La modifica è comunque stata salvata.");
            }
            else {
                errorNotification("Errore del server.");
            }
            
        }
    }, [refMonth, refYear, setOpenBudgetDialog]);


    return (
        <div
            className="expenses-item-container"
            style={{
                background: color.hex_code
            }}
        >
            <Icon className="expenses-item-icon" /* sx={{ fontSize: "10vw" }} */ />
            <div className="expenses-item-description" >
                {category.description}
            </div>
            <div className="expense-item-budget-container">
                <div className="expense-item-budgets">
                    <div className="expense-item-budgets-title">Iniziale</div>
                    {category.default_budget.toLocaleString("it-IT", { style: "currency", currency: "EUR" })}
                    <div className="expense-item-budgets-title">Residuo</div>
                    {(category.default_budget - expenseTotal).toLocaleString("it-IT", { style: "currency", currency: "EUR" })}

                </div>

                <Button
                    fullWidth
                    variant="contained"
                    className="button"
                    sx={{
                        height: "3vh"
                    }}
                    onClick={() => setOpenExpenseListDialog(true)}
                >
                    SPESE
                </Button>
            </div>

            <IconButton
                onClick={() => setOpenBudgetDialog(true)}
            >
                <SettingsIcon
                    className='icon-size'
                    sx={{
                        fontSize: "7vw"
                    }}
                />
            </IconButton>



            <SetExpenseCategoryBudgetDialog
                currentBudget={category.default_budget}
                open={openBudgetDialog}
                onClose={() => setOpenBudgetDialog(false)}
                onModify={setBudget}
            />

            <ExpensesListDialog
                open={openExpenseListDialog}
                onClose={() => setOpenExpenseListDialog(false)}
                categoryName={category.description}
                expenseList={category.expenses}
                categoryId={category.expense_category_id}
            />

        </div>
    )
}