import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { CategoryModel } from "../../../../../../../Models/CategoryModel";
import { useCallback, useEffect, useState } from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { errorNotification, successNotification, toggleLoadingOverlay } from "../../../../../../../utils";
import { ExpensesCategoriesAPI } from "../../../../../../../RemoteAPI/ExpensesCategories";

function getPositionDataStructure(categories = [{ ...CategoryModel }]) {
    const positionObject = [];
    categories.forEach(category => {
        positionObject.push({ expense_category_id: category.expense_category_id });
    })

    return positionObject;
}

export default function CategoryEditPositionDialog({ open, onClose, categories = [{ ...CategoryModel }], token, refreshCategories }) {

    const [categoriesView, setCategoriesView] = useState(categories)
    const [positions, setPositions] = useState(() => getPositionDataStructure(categories));

    useEffect(() => {
        setCategoriesView(categories)
    }, [categories])

    const onChangePosition = useCallback((startIndex, endIndex) => {

        console.log("startIndex", startIndex, "endIndex", endIndex)
        const result = [...categoriesView];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        setCategoriesView(result)
    }, [categoriesView])

    const onSave = useCallback(async () => {
        const body = categoriesView.map(category => category.expense_category_id);

        toggleLoadingOverlay()
        try {
            const result = await ExpensesCategoriesAPI.put.setOrder(token, body);
            const response = await result.json();

            if (result.status === 200) {
                successNotification(response.result.description)
                onClose();
                refreshCategories()
            }
            else {
                errorNotification(response.result.description)
            }
        } catch (error) {
            console.log(error);
            errorNotification("Errore del server.");
            toggleLoadingOverlay()
        }
        toggleLoadingOverlay()

    }, [categoriesView, token])

    /* console.log("test", onChangePosition(2, 5)) */


    return (
        <Dialog
            className="help-dialog"
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle
                className="help-dialog-title"
            >
                Riordina Categorie
            </DialogTitle>
            <DialogContent>
                {
                    categoriesView.map((category, eIndex) => <div
                        className="category-item-reorder"
                    >
                        <IconButton
                            onClick={() => onChangePosition(eIndex, eIndex - 1)}
                        >
                            <ArrowUpwardIcon className='icon-size' />
                        </IconButton>
                        <div>
                            {category.description}
                        </div>

                        <IconButton
                            onClick={() => onChangePosition(eIndex, eIndex + 1)}
                        >
                            <ArrowDownwardIcon className='icon-size' />
                        </IconButton>
                    </div>)
                }
            </DialogContent>
            <DialogActions>
                <Button
                    className="button"
                    variant="contained"
                    color="error"
                    onClick={() => onClose()}
                >
                    Annulla
                </Button>
                <Button
                    className="button"
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                >
                    Salva
                </Button>
            </DialogActions>
        </Dialog>
    )
}