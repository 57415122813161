import { useContext, useMemo, useState } from "react";
import "../Summary/summary.css";
import { AppContext } from "../../../../Context/AppContext";
import Chart from "react-google-charts";
import CalculateIcon from '@mui/icons-material/Calculate';
import { Button, IconButton } from "@mui/material";
import StatsSavingsSimulations from "./Components/StatsSavingsSimulations";
import StatsDetails from "./Components/StatsDetails";


export default function Stats({ }) {


    const [showSimulationTool, setShowSimulationTool] = useState(false);
    const [showStatsDetails, setShowStatsDetails] = useState(false);

    const AppData = useContext(AppContext)

    const totalYearMoneyIn = useMemo(() => {
        if (AppData.stats.value.total_money_in) {
            return AppData.stats.value.total_money_in;
        }
        return 0;
    }, [AppData.stats.value])

    const totalYearMoneyOut = useMemo(() => {
        if (AppData.stats.value.total_expenses) {
            return AppData.stats.value.total_expenses;
        }
        return 0;
    }, [AppData.stats.value])

    const totalYearBalance = useMemo(() => {
        return totalYearMoneyIn - totalYearMoneyOut;
    }, [totalYearMoneyIn, totalYearMoneyOut])

    const averageYearBalance = useMemo(() => {
        return totalYearBalance / 12;
    }, [totalYearBalance])

    const differenceBetweenFirstAndLastClosing = useMemo(() => {
        if (!AppData.stats.value.closings_graph_data || AppData.stats.value.closings_graph_data.length < 2) {
            return null;
        }
        const finalValue = AppData.stats.value.closings_graph_data[AppData.stats.value.closings_graph_data.length - 1][1];
        const initialValue = AppData.stats.value.closings_graph_data[0][1];

        return ((finalValue - initialValue) / Math.abs(initialValue)) * 100
    }, [AppData.stats.value.closings_graph_data])

    return (
        <div
            className="summary-container"
        >
            <div
                className="summary-money-in-total-container"
                style={{
                    padding: "1vh 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems : "center"
                }}
            >
                <Button
                    className="button"
                    variant="contained"
                    onClick={() => setShowStatsDetails(true)}
                >
                    Clicca per ottenere un analisi dettagliata
                </Button>
            </div>

            <div
                className="summary-money-in-total-container"
            >
                <div
                    className="summary-money-in-total-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    Statistiche totali {AppData.selectedYear.value}
                    <IconButton
                        color="primary"
                        onClick={() => setShowSimulationTool(true)}
                    >
                        <CalculateIcon
                            sx={{
                            fontSize: "8vw !important"
                        }} />
                    </IconButton>
                </div>
                <div className="summary-money-in-total-title">Entrate</div>
                <div className="summary-money-in-total-amount">
                    {totalYearMoneyIn.toLocaleString("it-IT", { currency: "EUR", style: "currency" })}
                </div>

                <div className="summary-money-in-total-title">Uscite</div>
                <div className="summary-money-in-total-amount">{totalYearMoneyOut.toLocaleString("it-IT", { currency: "EUR", style: "currency" })}</div>

                <div className="summary-money-in-total-title">Saldo</div>
                <div
                    className="summary-money-in-total-amount"
                    style={{
                        color: totalYearBalance < 0 ? "red" : "green"
                    }}
                >
                    {totalYearBalance.toLocaleString("it-IT", { currency: "EUR", style: "currency" })}
                </div>

                <div className="summary-money-in-total-title">Saldo Medio Mensile</div>
                <div
                    className="summary-money-in-total-amount"
                >
                    {averageYearBalance.toLocaleString("it-IT", { currency: "EUR", style: "currency" })}
                </div>

                <StatsSavingsSimulations
                    averageYearBalance={averageYearBalance}
                    open={showSimulationTool}
                    onClose={() => setShowSimulationTool(false)}
                />
            </div>
            <div
                className="summary-money-in-total-container"
            >
                <div className="summary-money-in-total-title">Andamento Saldo</div>
                {
                    AppData.stats.value.savings_graph_data && AppData.stats.value.savings_graph_data.length === 0 &&
                    <h4>
                        Dati insufficienti
                    </h4>
                }
                {AppData.stats.value.savings_graph_data && AppData.stats.value.savings_graph_data.length > 0 && <Chart
                    chartType="LineChart"
                    width="100%"
                    height="100%"
                    data={[["Mesi", "Saldo"], ...AppData.stats.value.savings_graph_data]}
                    options={{
                        title: "Andamento Saldi",
                        curveType: "function",
                        legend: { position: "bottom" },
                        hAxis: {
                            title: "Mesi"
                        },
                        vAxis: {
                            title: "Saldo"
                        }
                    }}
                    legendToggle
                />}
            </div>

            <div
                className="summary-money-in-total-container"
            >
                <div className="summary-money-in-total-title">Andamento Chiusure Mensili</div>
                {
                    AppData.stats.value.savings_graph_data && AppData.stats.value.closings_graph_data.length === 0 &&
                    <h4>
                        Dati insufficienti
                    </h4>
                }
                {
                    AppData.stats.value.savings_graph_data && AppData.stats.value.closings_graph_data.length > 0 && <Chart
                        chartType="LineChart"
                        width="100%"
                        height="100%"
                        data={[["Mesi", "Chiusure"], ...AppData.stats.value.closings_graph_data]}
                        options={{
                            title: "Andamento Chiusure",
                            curveType: "function",
                            legend: { position: "bottom" },
                            hAxis: {
                                title: "Mesi"
                            },
                            vAxis: {
                                title: "Importo"
                            }
                        }}
                        legendToggle
                    />
                }
                {
                    differenceBetweenFirstAndLastClosing !== null &&
                    <h4>
                        In base alle chiusure, la tua situazione globale è
                        {
                            differenceBetweenFirstAndLastClosing !== 0 ?
                                <>
                                    {differenceBetweenFirstAndLastClosing > 0 ? " migliorata" : " peggiorata"} del {differenceBetweenFirstAndLastClosing.toFixed(2)}%
                                </>
                                :
                                <>
                                    {" rimasta invariata"}
                                </>
                        }

                    </h4>
                }
                {
                    differenceBetweenFirstAndLastClosing === 0 &&
                    <>
                        In base alle chiusure, la tua situazione globale è rimasta invariata.
                    </>
                }
            </div>
            <StatsDetails
                open={showStatsDetails}
                onClose={() => setShowStatsDetails(false)}
            />
        </div>
    )
}