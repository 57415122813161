import "./pennypincherspinner.css"
import LogoCoin from "../../assets/logo-coin.png"

export default function PennyPincherSpinner({ }) {
    return (
        <img
            className="spinner"
            src={LogoCoin}
        />
    )
}