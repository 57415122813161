import { useCallback, useContext, useEffect, useState } from "react"
import { errorNotification, successNotification, toggleLoadingOverlay, validatePassword } from "../../../../../../utils"
import { UsersAPI } from "../../../../../../RemoteAPI/Users"
import { AppContext } from "../../../../../../Context/AppContext"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import "./profile.css"
import { AuthenticationModel } from "../../../../../../Models/AuthtenticationModel"
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin"
import { Views } from "../../../../../../Models/Enums"
import PennyPincherSpinner from "../../../../../PennyPincherSpinner/PennyPincherSpinner"

const EditModalDefault = {
    name: "",
    surname: ""
}

const EditModalPasswordDefault = {
    current_password: "",
    new_password: "",
    repeat_new_password: ""
}

const AlertDialogTypes = {
    LOGOUT: "logout",
    DELETE_ALL: "delete_all"
}

const AuthProvider = {
    NONE: "none",
    GOOGLE: "google"
}

export default function Profile({ }) {

    const [isLoading, setIsLoading] = useState(true);
    const [profileData, setProfileData] = useState({})

    const [openEditModal, setOpenEditModal] = useState(false)
    const [alertDialogType, setAlertDialogType] = useState("");

    const [formType, setFormType] = useState("")

    const [editModalForm, setEditModalForm] = useState(EditModalDefault)
    const [editPasswordForm, setEditPasswordForm] = useState(EditModalPasswordDefault)

    const AppData = useContext(AppContext)

    const getUserData = useCallback(async () => {
        /* toggleLoadingOverlay() */
        setIsLoading(true)
        try {
            const result = await UsersAPI.get.getCurrentUserData(AppData.authentication.value.token)
            const response = await result.json();

            if (result.status === 200) {
                setProfileData(response.data)
            }
            else {
                errorNotification(response.result.description)
            }
            /* toggleLoadingOverlay() */
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            errorNotification("Errore del server.");
            /* toggleLoadingOverlay() */
        }
    }, [AppData.authentication.value.token, setIsLoading])

    useEffect(() => {
        getUserData()
    }, [getUserData])

    const closeEditModal = useCallback(() => {
        setOpenEditModal(false)

        const timeoutId = setTimeout(() => {
            setEditModalForm(EditModalDefault)
            setEditPasswordForm(EditModalPasswordDefault)

            setFormType("")
            clearTimeout(timeoutId);
        }, 500)

    }, [setOpenEditModal])

    useEffect(() => {
        if (Object.keys(profileData).length > 0) {
            setEditModalForm({
                name: profileData.name,
                surname: profileData.surname
            })
        }

        if (formType !== "") {
            setOpenEditModal(true)
        }

    }, [profileData, formType])

    const onChangeForm = useCallback((e) => {
        setEditModalForm(old => ({ ...old, [e.target.name]: e.target.value }))
    })

    const onChangePasswordForm = useCallback(e => {
        setEditPasswordForm(old => ({ ...old, [e.target.name]: e.target.value }))
    })

    const logOut = useCallback(() => {
        SecureStoragePlugin.remove({ key: "auth" });
        AppData.selectedView.setValue(Views.LOGIN);
        AppData.stats.setValue({});
        AppData.expenses.setValue([]);
        AppData.moneyIn.setValue([])
    })

    const onDeleteUser = useCallback(async () => {
        toggleLoadingOverlay()
        try {

            const result = await UsersAPI.delete.deleteCurrentUser(AppData.authentication.value.token)

            const response = await result.json();

            if (result.status === 200) {
                successNotification("Dati eliminati correttamente.")
                logOut();
            }
            else {
                errorNotification(response.result.description)
            }
            toggleLoadingOverlay()
        } catch (error) {
            console.log(error)
            successNotification("Errore del server.")
            toggleLoadingOverlay()
        }

    }, [logOut])

    const onDialogSave = useCallback(async () => {

        if (formType === "pw") {
            if (!validatePassword(editPasswordForm.new_password)) {
                errorNotification("La password non rispecchia i criteri necessari.")
                return
            }
            if (editPasswordForm.current_password === "") {
                errorNotification("Inserire la password corrente.")
                return
            }
            if (editPasswordForm.new_password !== editPasswordForm.repeat_new_password) {
                errorNotification("Le password non coincidono")
                return
            }
        }

        toggleLoadingOverlay()
        try {
            let result = null;
            if (formType === "edit") {
                result = await UsersAPI.put.updateCurrentUser(AppData.authentication.value.token, editModalForm)
            }
            else if (formType === "pw") {
                result = await UsersAPI.put.changePassword(AppData.authentication.value.token, {
                    new_password: editPasswordForm.new_password,
                    old_password: editPasswordForm.current_password
                })
            }

            const response = await result.text();

            if (result.status === 200) {
                successNotification("Password modificata correttamente")
                closeEditModal()
                getUserData()
            }
            else {
                errorNotification(response.result.description)
            }
            toggleLoadingOverlay()
        } catch (error) {
            console.log(error)
            errorNotification("Errore del server.")
            toggleLoadingOverlay()
        }
    }, [editModalForm, editPasswordForm, formType, AppData.authentication.value.token, getUserData])

    return (
        <>
            <div
                className="profile-container"
            >

                <div
                    key="profile-tile"
                    className={`summary-money-in-total-container profile-tile`}
                >
                    {isLoading &&
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height : "20vh"
                            }}
                        >
                            <PennyPincherSpinner />
                        </div>

                    }
                    {!isLoading &&
                        <>
                            <div
                                className="profile-data-section"
                            >
                                <div
                                    style={{
                                        borderBottom: "1px solid black"
                                    }}
                                >
                                    <div className="profile-name-title">Nome e cognome</div>
                                    <div className="profile-name-content">
                                        {!profileData.name && "N.D."}
                                        {profileData?.name} {profileData?.surname}
                                    </div>
                                </div>
                                <div>
                                    <div className="profile-name-title">Email</div>
                                    <div className="profile-email-content">
                                        {!profileData.email && "N.D."}
                                        {profileData?.email}
                                    </div>
                                </div>
                            </div>

                            <div
                                className="profile-buttons-section"
                            >
                                <Button
                                    className="button"
                                    variant="contained"
                                    fullWidth
                                    onClick={() => AppData.showTutorial.setValue(true)}
                                >
                                    Visualizza Guida Iniziale
                                </Button>
                                <Button
                                    className="button"
                                    variant="contained"
                                    fullWidth
                                    onClick={() => setFormType("edit")}
                                >
                                    Modifica
                                </Button>
                                {
                                    profileData.auth_provider === AuthProvider.NONE &&
                                    <Button
                                        className="button"
                                        variant="contained"
                                        fullWidth
                                        onClick={() => setFormType("pw")}
                                    >
                                        Cambia Password
                                    </Button>
                                }
                                <Button
                                    className="button"
                                    variant="contained"
                                    fullWidth
                                    onClick={() => setAlertDialogType(AlertDialogTypes.LOGOUT)}
                                >
                                    Esci
                                </Button>
                                <Button
                                    className="button"
                                    variant="contained"
                                    fullWidth
                                    color="warning"
                                    onClick={() => setAlertDialogType(AlertDialogTypes.DELETE_ALL)}
                                >
                                    Richiedi cancellazione dei dati
                                </Button>
                            </div>
                        </>
                    }
                </div>

            </div>
            <Dialog
                className="help-dialog"
                open={openEditModal}
                maxWidth="lg"
                fullWidth
                onClose={closeEditModal}
            >
                <DialogTitle
                    className="help-dialog-title"
                >
                    Modifica {formType === "pw" ? "Password" : "Dati Utente"}
                </DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1vh",
                        padding: "1vh 2vw !important",
                        paddingBottom: "2vh !important"
                    }}
                >
                    {formType === "edit" &&
                        <>
                            <TextField
                                className="text-field-fonts"
                                value={editModalForm.name}
                                label={"Nome"}
                                name="name"
                                onChange={onChangeForm}
                            />
                            <TextField
                                className="text-field-fonts"
                                value={editModalForm.surname}
                                label={"Cognome"}
                                name="surname"
                                onChange={onChangeForm}
                            />
                        </>
                    }
                    {formType === "pw" &&
                        <>
                            <TextField
                                className="text-field-fonts"
                                type="password"
                                value={editPasswordForm.current_password}
                                label={"Password Corrente"}
                                name="current_password"
                                onChange={onChangePasswordForm}
                            />
                            <TextField
                                className="text-field-fonts"
                                type="password"
                                value={editPasswordForm.new_password}
                                label={"Nuova Password"}
                                name="new_password"
                                onChange={onChangePasswordForm}
                            />
                            <TextField
                                className="text-field-fonts"
                                type="password"
                                value={editPasswordForm.repeat_new_password}
                                label={"Ripeti Nuova Password"}
                                name="repeat_new_password"
                                onChange={onChangePasswordForm}
                            />
                        </>
                    }

                </DialogContent>
                <DialogActions>
                    <Button
                        className="button"
                        variant="contained"
                        fullWidth
                        color="error"
                        onClick={closeEditModal}
                    >
                        Annulla
                    </Button>
                    <Button
                        className="button"
                        variant="contained"
                        fullWidth
                        color="success"
                        onClick={onDialogSave}
                    >
                        Salva
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                className="help-dialog"
                open={alertDialogType !== ""}
                maxWidth="lg"
                fullWidth
                onClose={() => setAlertDialogType("")}
            >
                <DialogTitle>
                    {
                        alertDialogType === AlertDialogTypes.LOGOUT && <>Logout</>
                    }
                    {
                        alertDialogType === AlertDialogTypes.DELETE_ALL && <>Eliminazione account</>
                    }
                </DialogTitle>
                <DialogContent>
                    {
                        alertDialogType === AlertDialogTypes.LOGOUT && <>Sei sicuro di voler uscire ?</>
                    }
                    {
                        alertDialogType === AlertDialogTypes.DELETE_ALL && <>Questa procedura eliminerà in maniera irreversibile tutti i tuoi dati, sei sicuro di voler procedere?</>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button"
                        variant="contained"
                        fullWidth
                        color={alertDialogType === AlertDialogTypes.DELETE_ALL ? "success" : "error"}
                        onClick={() => setAlertDialogType("")}
                    >
                        Annulla
                    </Button>
                    <Button
                        className="button"
                        variant="contained"
                        fullWidth
                        color={alertDialogType === AlertDialogTypes.DELETE_ALL ? "error" : "success"}
                        onClick={alertDialogType === AlertDialogTypes.LOGOUT ? logOut : onDeleteUser}
                    >
                        {alertDialogType === AlertDialogTypes.DELETE_ALL ? "Elimina" : "Esci"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}