import { useContext, useState } from "react";
import "./recurrentperiods.css";
import { AppContext } from "../../../../../../Context/AppContext";
import { Fab, SpeedDialIcon } from "@mui/material";
import RecurrencyPeriodItem from "./Components/RecurrencyPeriodItem";
import RecurrencyPeriodsEditDialog from "./Dialogs/RecurrencyPeriodsEditDialog";

export default function RecurrencyPeriods({ }) {


    const [showAddDialog, setShowAddDialog] = useState(false)

    const AppData = useContext(AppContext)

    return (
        <div
            className="recurrency-periods-container"
        >
            {
                AppData.recurrencyPeriods.value.length === 0 &&
                <>
                    <div
                        className="categories-empty-container"
                    >
                        <div className="summary-money-in-total-container">
                            <h2>Nessun periodo riccorrente inserito</h2>
                            <h3>Inseriscili per poter accedere all'archivio in fase di registrazione di una spesa.</h3>
                        </div>
                    </div>
                </>
            }
            {AppData.recurrencyPeriods.value.map((period, index) => <RecurrencyPeriodItem
                token={AppData.authentication.value.token}
                key={`rpi-${period.recurrent_period_id}`}
                recurrencyPeriod={period}
                refreshCaller={AppData.recurrencyPeriods.refreshRecurrencyPeriods}
            />)}

            <Fab
                sx={{ position: 'absolute', bottom: 70, right: 16 }}
                color="primary"
                size="large"
                onClick={() => setShowAddDialog(true)}
            >
                <SpeedDialIcon />
            </Fab>

            <RecurrencyPeriodsEditDialog
                key={"rp-item-add-dialog"}
                open={showAddDialog}
                onClose={() => setShowAddDialog(false)}
            />
        </div>
    )
}