import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Step, StepLabel, Stepper } from "@mui/material";
import { useCallback, useContext, useMemo, useState } from "react";
import HelpContentSettings from "../../HelpContents/HelpContentSettings";
import HelpContentExpensesAddExpense from "../../HelpContents/HelpContentExpensesTabs/HelpContentExpensesAddExpense";
import HelpContentExpensesRecurrentExpenses from "../../HelpContents/HelpContentExpensesTabs/HelpContentExpensesRecurrentExpenses";
import menuBtn from "../../../../assets/tutorial/menu-btn.png"
import menuSettings from "../../../../assets/tutorial/menu-impostazioni.png"
import menuExpenses from "../../../../assets/tutorial/menu-uscite.png"
import menuMoneyin from "../../../../assets/tutorial/menu-entrate.png"
import menuReview from "../../../../assets/tutorial/menu-riepilogo.png"
import HelpContentStyledPar from "../../HelpContents/HelpContentsStyledPar";
import HelpMoneyIn from "../../HelpContents/HelpMoneyIn";
import HelpSummary from "../../HelpContents/HelpSummary";
import InfoIcon from '@mui/icons-material/Info';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { AppContext } from "../../../../Context/AppContext";

const steps = [
    "Aggiungi Categorie",
    "Aggiungi Spese",
    "Aggiungi Entrate",
    "Aggiungi Chiusure Reali",
    "Aiuti"
]

const SlideDirections = {
    RIGHT: "right",
    LEFT: "left"
}

const SlideTransitionDelay = 250;

export default function Tutorial({ open, onClose }) {

    const [activeStep, setActiveStep] = useState(0);
    const [slideDirection, setSlideDirection] = useState(SlideDirections.RIGHT);
    const [openCloseTutorialDialog, setOpenCloseTutorialDialog] = useState(false);

    const AppData = useContext(AppContext);

    const isLastStep = useMemo(() => {
        return activeStep === steps.length - 1
    }, [activeStep])


    const endTutorial = useCallback(() => {
        AppData.showTutorial.setValue(false);
        setActiveStep(0)
    }, [AppData])

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
            >
                <DialogTitle>
                    Per iniziare...
                </DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div
                        style={{
                            overflowY: "auto",
                            height: "74vh"
                        }}
                    >
                        <Slide
                            direction={slideDirection}
                            in={activeStep === 0}
                            mountOnEnter
                            unmountOnExit
                            style={{
                                transitionDelay: SlideTransitionDelay
                            }}
                        >
                            <div>
                                <h3>Le seguenti informazioni si riferiscono alla sezione Impostazioni, raggiungibile dal menù dell'app.</h3>
                                <HelpContentStyledPar>
                                    <img
                                        className="help-content-images-half"
                                        src={menuBtn}
                                    />
                                </HelpContentStyledPar>
                                <HelpContentStyledPar>
                                    <img
                                        className="help-content-images-half"
                                        src={menuSettings}
                                    />
                                </HelpContentStyledPar>
                                <HelpContentSettings />
                            </div>

                        </Slide>
                        <Slide
                            direction={slideDirection}
                            in={activeStep === 1}
                            mountOnEnter
                            unmountOnExit
                            style={{
                                transitionDelay: SlideTransitionDelay
                            }}
                        >
                            <div>
                                <h3>Le seguenti informazioni si riferiscono alla sezione Uscite, raggiungibile dal menù dell'app.</h3>
                                <HelpContentStyledPar>
                                    <img
                                        className="help-content-images-half"
                                        src={menuBtn}
                                    />
                                </HelpContentStyledPar>
                                <HelpContentStyledPar>
                                    <img
                                        className="help-content-images-half"
                                        src={menuExpenses}
                                    />
                                </HelpContentStyledPar>
                                <HelpContentExpensesAddExpense />
                                <h3>Spese Ricorrenti</h3>
                                <HelpContentExpensesRecurrentExpenses />
                            </div>
                        </Slide>
                        <Slide
                            direction={slideDirection}
                            in={activeStep === 2}
                            mountOnEnter
                            unmountOnExit
                            style={{
                                transitionDelay: SlideTransitionDelay
                            }}
                        >
                            <div>
                                <h3>Le seguenti informazioni si riferiscono alla sezione Entrate, raggiungibile dal menù dell'app.</h3>
                                <HelpContentStyledPar>
                                    <img
                                        className="help-content-images-half"
                                        src={menuBtn}
                                    />
                                </HelpContentStyledPar>
                                <HelpContentStyledPar>
                                    <img
                                        className="help-content-images-half"
                                        src={menuMoneyin}
                                    />
                                </HelpContentStyledPar>
                                <h3>Entrate</h3>
                                <HelpMoneyIn />
                            </div>
                        </Slide>
                        <Slide
                            direction={slideDirection}
                            in={activeStep === 3}
                            mountOnEnter
                            unmountOnExit
                            style={{
                                transitionDelay: SlideTransitionDelay
                            }}
                        >
                            <div>
                                <h3>Le seguenti informazioni si riferiscono alla sezione Riepilogo, raggiungibile dal menù dell'app.</h3>
                                <HelpContentStyledPar>
                                    <img
                                        className="help-content-images-half"
                                        src={menuBtn}
                                    />
                                </HelpContentStyledPar>
                                <HelpContentStyledPar>
                                    <img
                                        className="help-content-images-half"
                                        src={menuReview}
                                    />
                                </HelpContentStyledPar>
                                <h3>Chiusure Reali</h3>
                                <HelpSummary />
                            </div>
                        </Slide>
                        <Slide
                            direction={slideDirection}
                            in={activeStep === 4}
                            mountOnEnter
                            unmountOnExit
                            style={{
                                transitionDelay: SlideTransitionDelay
                            }}
                        >
                            <div>
                                <h3>
                                    I suggerimenti che hai appena visto non rispecchiano la totalità delle funzioni di PennyPincher,
                                    ma forniscono un indicazione su come iniziare ad utilizzare l'app.
                                </h3>
                                <h3>
                                    Durante la navigazione sarà possibile accedere ad ulteriori guide e suggerimenti cliccando sulle icone
                                    <InfoIcon className="button" /> e <HelpCenterIcon className="button" />
                                </h3>
                                <h3>
                                    Potrai comunque rivedere questo tutorial dalle Impostazioni nel tab Profilo.
                                </h3>
                                <h3>
                                    N.B. i tuoi dati non verranno utilizzati in alcun modo né ceduti a terzi e puoi eliminare il tuo profilo
                                    in ogni momento tramite la sezione Impostazioni nel tab Profilo.
                                </h3>
                                <h3>
                                    Buon divertimento con PennyPincher!
                                </h3>
                            </div>
                        </Slide>
                    </div>

                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    {activeStep > 0 &&
                        <Button
                            className="button"
                            variant="contained"
                            onClick={() => { setActiveStep(old => old - 1); setSlideDirection(SlideDirections.LEFT); }}
                        >
                            Indietro
                        </Button>
                    }
                    <Button
                        className="button"
                        variant="contained"
                        onClick={isLastStep ? endTutorial : () => setOpenCloseTutorialDialog(true)}
                    >
                        {isLastStep ? "Fine" : "Salta"}
                    </Button>
                    {!isLastStep &&
                        <Button
                            className="button"
                            variant="contained"
                            onClick={() => { setActiveStep(old => old + 1); setSlideDirection(SlideDirections.RIGHT) }}
                        >
                            Avanti
                        </Button>
                    }
                </DialogActions>
            </Dialog>
            <Dialog
                open={openCloseTutorialDialog}
                onClose={() => setOpenCloseTutorialDialog(false)}
            >
                <DialogTitle>
                    Termina Guida Iniziale
                </DialogTitle>
                <DialogContent>
                    Sei sicuro di voler saltare la guida iniziale?
                    <p>
                        Potrai comunque riverla dalla sezione Impostazioni nel tab Profilo.
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button"
                        variant="contained"
                        onClick={() => setOpenCloseTutorialDialog(false)}
                    >
                        Annulla
                    </Button>
                    <Button
                        className="button"
                        variant="contained"
                        color="warning"
                        onClick={() => {endTutorial(); setOpenCloseTutorialDialog(false)}}
                    >
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}