
import HelpContentStyledPar from "./HelpContentsStyledPar";
import moneyInAddButton from "../../../assets/moneyin/aggiunta_entrata.png";
import summaryMoneyIn from "../../../assets/moneyin/riepilogo_entrate.png";
import statsYearTotal from "../../../assets/moneyin/stat_tot_annue.png";
import statsBalanceChart from "../../../assets/moneyin/andamento_saldo.png";


export default function HelpMoneyIn({ }) {
    return (
        <div>
            <p>
                La sezione <b>Entrate</b> permette di registrare le entrate mensili.
            </p>
            <p>
                Per aggiungere una nuova entrata è sufficiente cliccare il pulsante <b>+</b> nella parte bassa dello schermo e compilare i dati. 
            </p>
            <HelpContentStyledPar>
                <img
                    src={moneyInAddButton}
                />
            </HelpContentStyledPar>
            <p>I dati inseriti sanno utilizzati per calcolare i vari saldi (reali e stimati) mensili visionabili nella sezione <b>Riepilogo</b>.</p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={summaryMoneyIn}
                />
            </HelpContentStyledPar>
            <p>Concorrono alla generazione delle <b>Statistiche Totali</b> annue nella sezione <b>Statistiche</b></p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={statsYearTotal}
                />
            </HelpContentStyledPar>
            <p>Inoltre questa Informazione è fondamentale per la generazione del grafico <b>Andamento Saldo</b> nella sezione <b>Statistiche</b></p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={statsBalanceChart}
                />
            </HelpContentStyledPar>
        </div>
    )
}