import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import LiquorIcon from '@mui/icons-material/Liquor';
import WineBarIcon from '@mui/icons-material/WineBar';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import BathroomIcon from '@mui/icons-material/Bathroom';
import SurfingIcon from '@mui/icons-material/Surfing';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import TapasIcon from '@mui/icons-material/Tapas';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import PaymentsIcon from '@mui/icons-material/Payments';
import SellIcon from '@mui/icons-material/Sell';
import RedeemIcon from '@mui/icons-material/Redeem';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

export const Icons = [
    ShoppingCartIcon,
    PaymentsIcon,
    SellIcon,
    RedeemIcon,
    ShoppingBasketIcon,
    ShoppingBagIcon,
    LocalBarIcon,
    LiquorIcon,
    WineBarIcon,
    BloodtypeIcon,
    BathroomIcon,
    SurfingIcon,
    FastfoodIcon,
    RestaurantMenuIcon,
    TapasIcon,
    LocalPizzaIcon,
    BakeryDiningIcon
] 