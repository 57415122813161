import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import Slide from '@mui/material/Slide';
import "./login.css";
import { useSpring, a } from "react-spring";
import { useCallback, useContext, useState } from "react";
import { errorNotification, getTextFieldStyle, successNotification, toggleLoadingOverlay, validatePassword } from "../../utils";
import { UsersAPI } from "../../RemoteAPI/Users";
import { AppContext } from "../../Context/AppContext";
import { Views } from "../../Models/Enums";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import HelpDialog from "../Main/Components/HelpDialogs/HelpDialog"

const RegistrationFormDefault = {
    name: "",
    surname: "",
    email: "",
    confirm_email: "",
    password: "",
    confirm_password : ""
}


export default function Login({ }) {

    const [isLoading, setIsLoading] = useState(false);

    const [showLoginForm, setShowLoginForm] = useState(true)
    const [showRegistrationForm, setShowRegistrationForm] = useState(false)
    const [showPasswordHelp, setShowPasswordHelp] = useState(false);

    const [loginForm, setLoginForm] = useState({
        email: "",
        password: ""
    })

    const [registrationForm, setRegistrationForm] = useState(RegistrationFormDefault)

    const [loginFormErrors, setLoginFormErrors] = useState({
        email: false,
        password: false
    })

    const [registrationFormErrors, setRegistrationFormErrors] = useState({
        email: false,
        password: false,
        confirm_password : false
    })

    const AppData = useContext(AppContext)

    const { opacity } = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: {
            duration: 1000
        },
        delay: 500
    })

    const { opacity2: opacitySub } = useSpring({
        from: { opacity2: 0 },
        to: { opacity2: 1 },
        config: {
            duration: 1000
        },
        delay: 1000
    })

    const { opacityForm } = useSpring({
        from: { opacityForm: 0 },
        to: { opacityForm: 1 },
        config: {
            duration: 1500
        },
        delay: 1500
    })

    const handleFormChange = useCallback((e) => {
        setLoginForm(old => ({ ...old, [e.target.name]: e.target.value }))
    }, [setLoginForm]);

    const handleRegistrationFormChange = useCallback((e) => {
        setRegistrationForm(old => ({ ...old, [e.target.name]: e.target.value }))
    }, [setLoginForm]);

    const onLoginClick = useCallback(async () => {
        if (loginForm.email === "" && !loginForm.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setLoginFormErrors(old => ({ ...old, email: true }))
            errorNotification("Formato della mail errato.")
            return;
        }
        if (loginForm.password === "") {
            setLoginFormErrors(old => ({ ...old, password: true }))
            errorNotification("Inserire una password.")
            return;
        }

        try {
            toggleLoadingOverlay()

            const result = await UsersAPI.post.authenticate(loginForm.email, loginForm.password);

            if (result.status === 404) {
                errorNotification("Credenziali errate.");
                toggleLoadingOverlay()
                return;
            }

            if (result.status !== 200) {
                errorNotification("Errore di autenticazione");
                toggleLoadingOverlay()
                return;
            }

            const response = await result.json();
            const yearsResult = await UsersAPI.get.getUserYears(response.data.token);
            const yearsResponse = await yearsResult.json();
            response.data.user.years = yearsResponse.data;

            AppData.authentication.setValue(response.data);
            AppData.selectedView.setValue(Views.MAIN)

            toggleLoadingOverlay()

        } catch (error) {
            errorNotification("Errore del server.")
            toggleLoadingOverlay()
        }

    }, [loginForm, setLoginFormErrors])

    const onShowRegistrationForm = useCallback(() => {
        setShowLoginForm(false)
        const timeoutId = setTimeout(() => {
            setShowRegistrationForm(true);
            clearTimeout(timeoutId);
        }, 500);
    }, []);

    const onShowLoginForm = useCallback(() => {
        setShowRegistrationForm(false)
        const timeoutId = setTimeout(() => {
            setShowLoginForm(true);
            clearTimeout(timeoutId);
            setRegistrationForm(RegistrationFormDefault)
        }, 500);
    }, [])

    const onRegistrationSubmit = useCallback(async () => {
        
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (registrationForm.email !== registrationForm.confirm_email) {
            setRegistrationFormErrors(old => ({ ...old, email : true }));   
            errorNotification("Le due email non coincidono.")
            return;
        }

        if (!emailRegex.test(registrationForm.email)) {
            setRegistrationFormErrors(old => ({ ...old, email : true }));   
            errorNotification("Il formato dell'email non è corretto.")
            return;
        }

        if (registrationForm.password !== registrationForm.confirm_password) {
            setRegistrationFormErrors(old => ({ ...old, password: true, confirm_password: true }));   
            errorNotification("Le passwords non coincidono.")
            return;
        }

        if (!validatePassword(registrationForm.password)) {
            setRegistrationFormErrors(old => ({ ...old, password: true }));
            errorNotification("La password non rispetta i criteri necessari.")
            return
        }

        try {
            toggleLoadingOverlay()

            const result = await UsersAPI.post.createUser(registrationForm.email,
                registrationForm.name, registrationForm.surname, registrationForm.password
            )

            if (result.status === 400) {
                errorNotification("Esiste già un profilo con questa email.");
                toggleLoadingOverlay()
                return;
            }

            if (result.status !== 201) {
                errorNotification("Errore del server. Riprova più tardi.");
                toggleLoadingOverlay()
                return;
            }

            successNotification("Nuovo profilo creato con successo!");
            onShowLoginForm();

            toggleLoadingOverlay()

        } catch (error) {
            errorNotification("Errore del server.")
            toggleLoadingOverlay()
        }
    }, [registrationForm]);

    return (
        <div className="login-container">

            <Slide
                direction="down"
                in={showRegistrationForm}
                mountOnEnter
                unmountOnExit
            >
                <div className="login-form-back-container" onClick={onShowLoginForm}><ArrowBackIcon /> TORNA AL LOGIN</div>
            </Slide>

            <Slide
                in={showLoginForm}
                direction="right"
                mountOnEnter
                unmountOnExit
            >
                <div className="summary-money-in-total-container login-body">
                    <div className="login-title">
                        <a.h1 style={{ opacity: opacity }}>
                            Benvenuto
                        </a.h1>
                        <a.h2 style={{ opacity: opacitySub, textAlign: "center" }}>
                            Inserisci i tuoi dati per accedere
                        </a.h2>
                    </div>
                    <a.div className="login-form" style={{ opacity: opacityForm }}>
                        <TextField
                            className="text-field-fonts"
                            variant="filled"
                            placeholder="Email"
                            type="email"
                            name="email"
                            error={loginFormErrors.email}
                            value={loginForm.email}
                            onChange={handleFormChange}
                        />
                        <TextField
                            className="text-field-fonts"
                            name="password"
                            variant="filled"
                            placeholder="Password"
                            type="password"
                            value={loginForm.password}
                            error={loginFormErrors.password}
                            onChange={handleFormChange}
                        />
                        <Button
                            variant="contained"
                            onClick={onLoginClick}
                            className="button"
                        >
                            Accedi
                        </Button>
                        <a onClick={onShowRegistrationForm}>Non hai un account? Clicca qui per registrarti</a>
                    </a.div>
                </div>
            </Slide>

            <Slide
                direction="left"
                in={showRegistrationForm}
                mountOnEnter
                unmountOnExit
            >
                <div className="summary-money-in-total-container login-body">
                    <h3
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems : "center"
                        }}
                    >
                        Compila i dati per registrarti
                        <IconButton
                            className="icon-size"
                            onClick={() => setShowPasswordHelp(true)}
                        >
                            <InfoIcon />
                        </IconButton>
                    </h3>
                    <a.div className="login-form registration-form" style={{ opacity: opacityForm }}>
                        <TextField
                            className="text-field-fonts"
                            variant="filled"
                            placeholder="Email"
                            type="email"
                            name="email"
                            error={registrationFormErrors.email}
                            value={registrationForm.email}
                            onChange={handleRegistrationFormChange}
                        />
                        <TextField
                            className="text-field-fonts"
                            variant="filled"
                            placeholder="Conferma Email"
                            type="email"
                            name="confirm_email"
                            error={registrationFormErrors.email}
                            value={registrationForm.confirm_email}
                            onChange={handleRegistrationFormChange}
                        />
                        <TextField
                            className="text-field-fonts"
                            variant="filled"
                            placeholder="Nome"
                            type="text"
                            name="name"
                            value={registrationForm.name}
                            onChange={handleRegistrationFormChange}
                        />
                        <TextField
                            className="text-field-fonts"
                            variant="filled"
                            placeholder="Cognome"
                            type="text"
                            name="surname"
                            error={registrationForm.surname}
                            onChange={handleRegistrationFormChange}
                        />

                        <TextField
                            className="text-field-fonts"
                            name="password"
                            variant="filled"
                            placeholder="Password"
                            type="password"
                            value={registrationForm.password}
                            error={registrationFormErrors.password}
                            onChange={handleRegistrationFormChange}
                        />
                        <TextField
                            className="text-field-fonts"
                            name="confirm_password"
                            variant="filled"
                            placeholder="Conferma Password"
                            type="password"
                            value={registrationForm.confirm_password}
                            error={registrationFormErrors.confirm_password}
                            onChange={handleRegistrationFormChange}
                        />
                        <Button
                            variant="contained"
                            onClick={onRegistrationSubmit}
                            className="button"
                        >
                            Registrati
                        </Button>
                    </a.div>
                </div>
            </Slide>
            <HelpDialog
                showHelp={showPasswordHelp}
                onClose={() => setShowPasswordHelp(false)}
            >
                <h3>Caratteristiche Password</h3>
                <ul>
                    <li>Lunghezza compresa tra 8 e 16 caratteri</li>
                    <li>Almeno un carattere speciale tra: @ . # $ ! % * ? &</li>
                    <li>Almeno un numero</li>
                    <li>Almeno una lettera maiuscola e una minuscola</li>
                </ul>
            </HelpDialog>
        </div>
    )
}