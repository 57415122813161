export const AuthenticationModel = {
    token : "",
    refresh_token : "",
    user: {
        user_id: 0,
        name: "",
        surname: "",
        email: "",
        update_date: "",
        years : []
    }
}