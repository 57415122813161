import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import "./appdrawer.css";
import { MainViews } from "../../MainViews";
import { useCallback } from "react";

export default function AppDrawer({ open, onClose, selectedMainView, setSelectedMainView }) {


    const onItemClick = useCallback((item) => {
        setSelectedMainView(item)
        onClose()
    }, [setSelectedMainView])

    return (
        <Drawer
            open={open}
            onClose={onClose}
        >
            <div className={`drawer-item ${selectedMainView === MainViews.SUMMARY ? "active": ""}`} onClick={onItemClick.bind(this, MainViews.SUMMARY)}>RIEPILOGO</div>
            <div className={`drawer-item ${selectedMainView === MainViews.MONEYIN ? "active": ""}`} onClick={onItemClick.bind(this, MainViews.MONEYIN)}>ENTRATE</div>
            <div className={`drawer-item ${selectedMainView === MainViews.EXPENSES ? "active": ""}`} onClick={onItemClick.bind(this, MainViews.EXPENSES)}>USCITE</div>
            <Divider />
            <div className={`drawer-item ${selectedMainView === MainViews.STATS ? "active": ""}`} onClick={onItemClick.bind(this, MainViews.STATS)}>STATISTICHE</div>
            <div className={`drawer-item ${selectedMainView === MainViews.SETTINGS ? "active": ""}`} onClick={onItemClick.bind(this, MainViews.SETTINGS)}>IMPOSTAZIONI</div>
        </Drawer>
    )
}