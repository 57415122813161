import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { RecurrencyPeriodsModel } from "../../../../../../../Models/RecurrencyPeriodsModel";
import SettingsIcon from '@mui/icons-material/Settings';
import RecurrencyPeriodsEditDialog from "../Dialogs/RecurrencyPeriodsEditDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import { useCallback, useState } from "react";
import { errorNotification, successNotification } from "../../../../../../../utils";
import { RecurrencyPeriodsAPI } from "../../../../../../../RemoteAPI/RecurrencyPeriods";

export default function RecurrencyPeriodItem({ recurrencyPeriod = { ...RecurrencyPeriodsModel }, refreshCaller = () => {}, token }) {

    const [showEditDialog, setShowEditDialog] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const onDelete = useCallback(async () => {
        try {
            const result = await RecurrencyPeriodsAPI.delete.deleteRecurrencyPeriod(token, recurrencyPeriod.recurrency_period_id);
            const response = await result.json()

            if (result.status === 200) {
                successNotification(response.result.description);
                refreshCaller()
                setShowDeleteDialog(false)
            }
            else {
                errorNotification(response.result.description)
            }
            
        } catch (error) {
            errorNotification("Errore interno.")
        }
    }, [recurrencyPeriod, token])


    return (
        <>
            <div className="recurrency-period-item">
                <div className="description" >{recurrencyPeriod.description}</div>
                <div>
                    <IconButton
                        onClick={() => setShowEditDialog(true)}
                    >
                        <SettingsIcon
                            className='icon-size'
                            sx={{
                                fontSize: "7vw"
                            }}
                        />
                    </IconButton>
                    <IconButton
                        onClick={() => setShowDeleteDialog(true)}
                    >
                        <DeleteIcon
                            className='icon-size'
                            sx={{
                                fontSize: "7vw"
                            }}
                        />
                    </IconButton>
                </div>

            </div>
            <RecurrencyPeriodsEditDialog
                key={`rp-item-edit-dialog-${recurrencyPeriod.recurrency_period_id}`}
                open={showEditDialog}
                onClose={() => setShowEditDialog(false)}
                recurrencyPeriod={recurrencyPeriod}
            />
            <Dialog
                className="help-dialog"
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle
                    className="help-dialog-title"
                >
                    {recurrencyPeriod.description}
                </DialogTitle>
                <DialogContent>
                    Sei sicuro di voler eliminare il periodo ricorrente?
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button"
                        variant="contained"
                        color="success"
                        onClick={() => setShowDeleteDialog(false)}
                    >
                        Annulla
                    </Button>
                    <Button
                        className="button"
                        variant="contained"
                        color="error"
                        onClick={onDelete}
                    >
                        Elimina
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}