import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "../../../../../Context/AppContext";
import { ExpensesAPI } from "../../../../../RemoteAPI/Expenses";
import { buildDate, errorNotification, successNotification, toggleLoadingOverlay } from "../../../../../utils";
import { ExpenseModel } from "../../../../../Models/ExpenseModel";
import { RecurrencyPeriodsModel } from "../../../../../Models/RecurrencyPeriodsModel";
import HelpDialog from "../../../Components/HelpDialogs/HelpDialog";
import HelpContentExpensesRecurrentExpenses from "../../../HelpContents/HelpContentExpensesTabs/HelpContentExpensesRecurrentExpenses";
import InfoIcon from '@mui/icons-material/Info';

export default function AddExpenseDialog({ open, onClose, expenseCategoryId, expense = { ...ExpenseModel } }) {

    const [showRecurrentHelp, setShowRecurrentHelp] = useState(false)

    const [form, setForm] = useState({
        title: expense && expense.expense_id !== 0 ? expense.title : "",
        description: expense && expense.expense_id !== 0 ? expense.description : "",
        amount: expense && expense.expense_id !== 0 ? expense.amount : "",
        date: expense && expense.expense_id !== 0 ? expense.date : "",
        recurrenteExpense: false,
        selectedRecurrenceType: "monthly",
        days: "",
        months: ""
    })


    const [selectedRecurrencyPeriod, setSelectedRecurrencyPeriod] = useState(null)

    const switchRef = useRef(null);

    const AppData = useContext(AppContext);

    useEffect(() => {
        if (expense.expense_id !== 0 && open) {
            setForm({
                title: expense && expense.expense_id !== 0 ? expense.title : "",
                description: expense && expense.expense_id !== 0 ? expense.description : "",
                amount: expense && expense.expense_id !== 0 ? expense.amount : "",
                date: expense && expense.expense_id !== 0 ? expense.date : "",
                recurrenteExpense: false,
                selectedRecurrenceType: "monthly",
                days: "",
                months: ""
            })
        }
    }, [open, expense])


    const onFormValueChange = useCallback((e) => {
        let value = e.target.value;
        if (value !== "" && !isNaN(value) && e.target.name !== "amount") {
            value = parseInt(value);
        }
        else if (value !== "" && e.target.name === "amount") {
            value = parseFloat(value);
        }

        setForm(old => ({ ...old, [e.target.name]: value }))
    }, [setForm])

    const onCloseWrapper = useCallback(() => {

        setForm({
            title: expense && expense.expense_id !== 0 ? expense.title : "",
            description: expense && expense.expense_id !== 0 ? expense.description : "",
            amount: expense && expense.expense_id !== 0 ? expense.amount : "",
            date: expense && expense.expense_id !== 0 ? expense.date : "",
            recurrenteExpense: false,
            selectedRecurrenceType: "monthly",
            days: "",
            months: ""
        })


        onClose();
    }, [onClose, expense])

    const dateLimits = useMemo(() => {
        const lastDay = new Date(AppData.selectedYear.value, AppData.selectedMonth.value + 1, 0);
        const firstDay = new Date(AppData.selectedYear.value, AppData.selectedMonth.value, 1);

        return {
            min: buildDate(firstDay),
            max: buildDate(lastDay)
        }
    }, [AppData.selectedMonth.value, AppData.selectedYear.value])


    const onSave = useCallback(async () => {
        try {
            toggleLoadingOverlay();
            let result = null;
            if (expense && expense.expense_id !== 0) {
                let updateRecurrents = false
                if (expense.expense_id_recurrent_parent !== null) {
                    updateRecurrents = switchRef.current.firstChild.checked
                }
                result = await ExpensesAPI.put.updateExpense(AppData.authentication.value.token,
                    expense.expense_id,
                    {
                        amount: parseFloat(form.amount),
                        date: form.date,
                        description: form.description,
                        expense_category_id: expenseCategoryId,
                        title: form.title
                    },
                    updateRecurrents
                )
            }
            else {
                result = await ExpensesAPI.post.createExpense(
                    AppData.authentication.value.token,
                    {
                        amount: parseFloat(form.amount),
                        date: form.date,
                        description: form.description,
                        expense_category_id: expenseCategoryId,
                        title: form.title
                    },
                    form.months === "" || form.months < 1 ? 0 : form.months,
                    form.days === "" || form.days < 1 ? 0 : form.days,
                )


                if (form.recurrenteExpense) {
                    const insertedDate = new Date(form.date);
                    const month = insertedDate.getMonth() + 1;
                    let monthToAdd = form.months;
                    if (form.days !== "") {
                        //nel caso di ricorrenza custom proviamo a stimare il numero di mesi per capire se sfora anno.
                        //chiaramente è una stima perché divide per 30, ma va comunque bene.
                        monthToAdd = (form.days * form.months) / 30
                    }


                    if ((month + monthToAdd) > 12) {
                        //abbiamo superato l'anno, quindi vale la pena ricaricare gli anni.
                        AppData.authentication.refreshYears();
                    }
                }
            }


            const response = await result.json();

            if (result.status === 201 || result.status === 200) {
                AppData.expenses.refresh();
                successNotification(response.result.description)
                onCloseWrapper()
            }
            else {
                errorNotification(response.result.description)
            }
            toggleLoadingOverlay();
        } catch (error) {
            console.log(error);
            errorNotification("Errore del server.");
            toggleLoadingOverlay();
        }
    }, [form, onCloseWrapper, expense, AppData.authentication])

    const onChangeRecurrencyPeriod = useCallback((event, newValue = { ...RecurrencyPeriodsModel }) => {
        setSelectedRecurrencyPeriod(newValue);
        if (newValue !== null) {
            setForm(old => ({
                ...old,
                selectedRecurrenceType: (newValue.repetition_days === 0 ? "monthly" : "custom"),
                days: newValue.repetition_days,
                months: newValue.repetition_count
            }))
        }
        else {
            setForm(old => ({
                ...old,
                selectedRecurrenceType: "monthly",
                days: "",
                months: ""
            }))
        }

    }, [setForm, setSelectedRecurrencyPeriod])


    return (
        <Dialog
            className="help-dialog"
            open={open}
            onClose={onCloseWrapper}
            maxWidth={"md"}
            fullWidth
        >
            <DialogTitle
                className="help-dialog-title"
            >
                Aggiungi Spesa
            </DialogTitle>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1vh",
                    paddingTop: "1vh !important"
                }}
            >
                <TextField className="text-field-fonts" type="text" label="Titolo" name="title" fullWidth onChange={onFormValueChange} value={form.title} />
                <TextField className="text-field-fonts" type="text" label="Descrizione" name="description" fullWidth multiline rows={4} onChange={onFormValueChange} value={form.description} />
                <TextField
                    className="text-field-fonts"
                    type="date"
                    label="Data"
                    name="date"
                    value={form.date}
                    onChange={onFormValueChange}
                    inputProps={{
                        min: dateLimits.min,
                        max: dateLimits.max
                    }}
                />

                <TextField
                    className="text-field-fonts"
                    type="number"
                    label="Importo"
                    name="amount"
                    fullWidth
                    onChange={onFormValueChange}
                    value={form.amount}
                />

                {expense.expense_id === 0 &&
                    <div
                        style={{
                            marginBottom: "2vh"
                        }}
                    >
                        <IconButton
                            onClick={() => setShowRecurrentHelp(true)}
                        >
                            <InfoIcon className='icon-size' />
                        </IconButton>
                        <FormControlLabel
                            control={<Switch checked={form.recurrenteExpense} onChange={(e, checked) => setForm(old => ({ ...old, recurrenteExpense: checked }))} />}
                            label="Spesa Ricorrente"
                        />
                        <HelpDialog
                            showHelp={showRecurrentHelp}
                            onClose={() => setShowRecurrentHelp(false)}
                        >
                            <HelpContentExpensesRecurrentExpenses />
                        </HelpDialog>
                    </div>

                }

                {expense.expense_id !== 0 && expense.expense_id_recurrent_parent &&
                    <div
                    >
                        <p>
                            Vuoi modificare anche le spese ricorrenti per i mesi successivi?
                        </p>
                        <h4>La modifica non avrà effetto sulla data.</h4>
                        <span>NO</span><Switch ref={switchRef} /><span>SI</span>
                    </div>
                }

                {form.recurrenteExpense &&
                    <>
                        <Autocomplete
                            options={AppData.recurrencyPeriods.value}
                            getOptionLabel={option => option.description}
                            isOptionEqualToValue={option => option.recurrency_period_id}
                            id="recurrency_periods_select"
                            clearOnBlur
                            selectOnFocus
                            handleHomeEndKeys
                            value={selectedRecurrencyPeriod}
                            onChange={onChangeRecurrencyPeriod}
                            renderInput={(params) => (
                                <TextField className="text-field-fonts" {...params} label="Archivio spese ricorrenti" />
                            )}
                        />
                        <FormControl>
                            <InputLabel id="rec-type-label">Tipo Ricorrenza</InputLabel>
                            <Select
                                labelId="rec-type-label"
                                id="rec-type"
                                label="Tipo Ricorrenza"
                                value={form.selectedRecurrenceType}
                                onChange={(e) => setForm(old => ({ ...old, selectedRecurrenceType: e.target.value }))}
                            >
                                <MenuItem value={"monthly"}>Mensile</MenuItem>
                                <MenuItem value={"custom"}>Custom</MenuItem>
                            </Select>
                        </FormControl>

                        <div
                            style={{
                                display: "flex"
                            }}
                        >

                            {
                                form.selectedRecurrenceType === "custom" && <>
                                    <TextField
                                        className="text-field-fonts"
                                        label="Giorni"
                                        fullWidth
                                        value={form.days}
                                        name="days"
                                        onChange={onFormValueChange}
                                    />
                                    <div className="expense-edit-dialog-multiply">X</div>
                                </>
                            }
                            <TextField
                                className="text-field-fonts"
                                label={form.selectedRecurrenceType === "custom" ? "Volte" : "Mesi"}
                                fullWidth
                                value={form.months}
                                name="months"
                                onChange={onFormValueChange}
                            />
                        </div>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={onCloseWrapper} className="button">Annulla</Button>
                <Button variant="contained" onClick={onSave} className="button">Salva</Button>
            </DialogActions>
        </Dialog>
    )
}