import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import { Views } from "../Models/Enums";
import { Network } from "@capacitor/network";
import { AuthenticationModel } from "../Models/AuthtenticationModel";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { authorizationPath, toggleLoadingOverlay } from "../utils";
import { UsersAPI } from "../RemoteAPI/Users";
import { ExpensesAPI } from "../RemoteAPI/Expenses";
import { CategoryModel } from "../Models/CategoryModel";
import { ExpenseModel } from "../Models/ExpenseModel";
import { MoneyInModel } from "../Models/MoneyInModel";
import { MoneyInAPI } from "../RemoteAPI/MoneyIn";
import { ColorModel } from "../Models/ColorModel";
import { ColorsAPI } from "../RemoteAPI/Colors";
import { SummaryAPI } from "../RemoteAPI/Summary";
import { useErrorBoundary } from "react-error-boundary";
import { RecurrencyPeriodsModel } from "../Models/RecurrencyPeriodsModel";
import { RecurrencyPeriodsAPI } from "../RemoteAPI/RecurrencyPeriods";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { Capacitor } from "@capacitor/core";

const StatsModel = {
    expected_saving: 0,
    total_money_in: 0,
    total_expenses: 0,
    month_closing: 0,
    month_closing_grow: 0,
    previous_month_closing: 0,
    savings_graph_data: [],
    closings_graph_data: []
}

export const AppContext = createContext({
    authentication: {
        value: AuthenticationModel,
        setValue: (old = AuthenticationModel) => { },
        refreshYears: () => { }
    },
    selectedView: {
        value: "",
        setValue: (old = "") => { }
    },
    internetAvailable: {
        value: false
    },
    selectedMonth: {
        value: 0,
        setValue: (old = 0) => { }
    },
    selectedYear: {
        value: 0,
        setValue: (old = 0) => { }
    },
    expenses: {
        value: [{ ...CategoryModel, expenses: [ExpenseModel] }],
        setValue: (old = [{ ...CategoryModel, expenses: [ExpenseModel] }]) => { },
        refresh: () => { }
    },
    moneyIn: {
        value: [{ ...MoneyInModel }],
        setValue: (old = [{ ...MoneyInModel }]) => { },
        refresh: () => { }
    },
    stats: {
        value: { ...StatsModel },
        setValue: (old = { ...StatsModel }) => { },
        refreshStats: (toggleLoading = true) => { }
    },
    colors: {
        value: [{ ...ColorModel }],
        setValue: (old = [{ ...ColorModel }]) => { }
    },
    recurrencyPeriods: {
        value: [{ ...RecurrencyPeriodsModel }],
        setValue: (old = [{ ...RecurrencyPeriodsModel }]) => { },
        refreshRecurrencyPeriods: () => { }
    },
    showTutorial: {
        value: false,
        setValue: (value = false) => { }
    }
})

export default function AppContextProvider({ children }) {

    const [authentication, setAuthentication] = useState(AuthenticationModel);
    const [selectedView, setSelectedView] = useState(Views.SPLASH);
    const [internetAvailable, setInternetAvailable] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState(() => {
        const date = new Date();
        return date.getMonth();
    })
    const [selectedYear, setSelectedYear] = useState(() => {
        const date = new Date();
        return date.getFullYear();
    })

    const [expenses, setExpenses] = useState([])
    const [moneyIn, setMoneyIn] = useState([])
    const [colors, setColors] = useState([])
    const [stats, setStats] = useState({});
    const [recurrencyPeriods, setRecurrencyPeriods] = useState([]);

    const [showTutorial, setShowTutorial] = useState(false)

    const { showBoundary } = useErrorBoundary()

    useLayoutEffect(() => {
        Network.getStatus().then((status) => {
            setInternetAvailable(status.connected)
        })

        SecureStoragePlugin.get({ key: "auth" })
            .then(async SSResult => {

                const auth = JSON.parse(SSResult.value)
                let result = null;
                try {
                    result = await UsersAPI.post.refreshToken(auth.refresh_token)
                } catch (error) {
                    showBoundary(error)
                }

                if (result.status === 200) {
                    const response = await result.json();
                    const yearsResult = await UsersAPI.get.getUserYears(response.data.token);
                    const yearsResponse = await yearsResult.json();
                    response.data.user.years = yearsResponse.data;
                    setAuthentication(response.data);
                    /* if (selectedView === Views.LOGIN) { */
                        setSelectedView(Views.MAIN)
                    /* } */

                    SecureStoragePlugin.get({ key: "show-tutorial" })
                        .then(response => {
                            console.log(response)
                            setShowTutorial(response.value === 'true')
                        })
                        .catch(error => {
                            setShowTutorial(true);
                        })
                }
                else {
                    setSelectedView(Views.LOGIN)
                }
            })
            .catch(async reason => {
                console.log(reason)
                setSelectedView(Views.LOGIN)
            })
        setSelectedView(Views.LOGIN)
    }, [])

    useEffect(() => {
        
        const mustFinish = 5;
        let finished = 0;
        if (authentication.token !== "") {
            toggleLoadingOverlay()
            ExpensesAPI.get.getExpenses(selectedYear, selectedMonth + 1, authentication.token)
                .then(async response => {
                    const data = await response.json();
                    setExpenses(data.data)
                    finished++
                    if (finished === mustFinish) {
                        toggleLoadingOverlay()
                    }
                })
                .catch(error => {
                    finished++
                    showBoundary(error)
                })

            MoneyInAPI.get.redMoneyIn(selectedYear, selectedMonth + 1, authentication.token)
                .then(async response => {
                    const moneyInRes = await response.json();
                    setMoneyIn(moneyInRes.data)
                    finished++
                    if (finished === mustFinish) {
                        toggleLoadingOverlay()
                    }
                })
                .catch(error => {
                    finished++
                    showBoundary(error)
                })

            SummaryAPI.get.getStats(authentication.token, selectedYear, selectedMonth + 1)
                .then(async response => {
                    const stats = await response.json();
                    setStats(stats.data)
                    finished++
                    if (finished === mustFinish) {
                        toggleLoadingOverlay()
                    }
                })
                .catch(error => {
                    finished++
                    console.log(error);
                    showBoundary(error)
                })

            RecurrencyPeriodsAPI.get.readRecurrencyPeriods(authentication.token)
                .then(async response => {
                    const data = await response.json();
                    setRecurrencyPeriods(data.data);
                    finished++;
                    if (finished === mustFinish) {
                        toggleLoadingOverlay()
                    }
                })
                .catch(error => {
                    finished++
                    console.log(error);
                    showBoundary(error)
                })

            if (colors.length === 0) {
                ColorsAPI.get.getColors(authentication.token)
                    .then(async result => {
                        const colorsResp = await result.json();
                        setColors(colorsResp.data);
                        finished++
                        if (finished === mustFinish) {
                            toggleLoadingOverlay()
                        }
                    })
                    .catch(error => {
                        finished++
                        console.log(error);
                        showBoundary(error)
                    })
            }
            else {
                finished++;
            }
        }

    }, [selectedMonth, selectedYear, authentication.token, setExpenses, setMoneyIn])

    Network.addListener("networkStatusChange", (status) => {
        /* const timeoutId = setTimeout(() => {
            setInternetAvailable(status.connected)
            console.log("st", status)
            if (status.connected && authentication.token === "") {
                setSelectedView(Views.LOGIN)
            }
            else if (status.connected && authentication.token !== "") {
                setSelectedView(Views.MAIN)
            }
            clearTimeout(timeoutId)
        }, 1000) */
    })

    const finalizeAuthentication = useCallback((data = AuthenticationModel) => {
        setAuthentication(data);
        SecureStoragePlugin.set({ key: "auth", value: JSON.stringify(data) })
            .then(result => console.log(result))
    }, [setAuthentication])

    const refreshMoneyIn = useCallback(async () => {
        toggleLoadingOverlay()
        const result = await MoneyInAPI.get.redMoneyIn(selectedYear, selectedMonth + 1, authentication.token);
        const response = await result.json();
        setMoneyIn(response.data)
        toggleLoadingOverlay()
    }, [setMoneyIn, selectedYear, selectedMonth, authentication])

    const refreshStats = useCallback(async (toggleLoading = true) => {
        if (toggleLoading)
            toggleLoadingOverlay()
        const result = await SummaryAPI.get.getStats(authentication.token, selectedYear, selectedMonth + 1);
        const response = await result.json();
        setStats(response.data);
        if (toggleLoading)
            toggleLoadingOverlay()
    }, [authentication, selectedMonth, selectedYear])

    const refreshExpenses = useCallback(async () => {
        toggleLoadingOverlay()
        const result = await ExpensesAPI.get.getExpenses(selectedYear, selectedMonth + 1, authentication.token);
        const response = await result.json();
        setExpenses(response.data)
        refreshStats();
        toggleLoadingOverlay()
    }, [setMoneyIn, selectedYear, selectedMonth, authentication, refreshStats])

    const refreshYears = useCallback(async () => {
        if (authentication.token !== "") {
            const result = await UsersAPI.get.getUserYears(authentication.token);
            const response = await result.json();

            if (result.status === 200) {
                setAuthentication(old => ({ ...old, user: { ...old.user, years: response.data } }))
            }
        }

    }, [setAuthentication, authentication]);

    const refreshRecurrencyPeriods = useCallback(async () => {
        toggleLoadingOverlay();
        const result = await RecurrencyPeriodsAPI.get.readRecurrencyPeriods(authentication.token)
        const response = await result.json();
        setRecurrencyPeriods(response.data);
        toggleLoadingOverlay();
    }, [setRecurrencyPeriods, authentication])




    const contextValue = {
        authentication: {
            value: authentication,
            setValue: finalizeAuthentication,
            refreshYears
        },
        selectedView: {
            value: selectedView,
            setValue: setSelectedView
        },
        internetAvailable: {
            value: internetAvailable
        },
        selectedMonth: {
            value: selectedMonth,
            setValue: setSelectedMonth
        },
        selectedYear: {
            value: selectedYear,
            setValue: setSelectedYear
        },
        expenses: {
            value: expenses,
            setValue: setExpenses,
            refresh: refreshExpenses
        },
        moneyIn: {
            value: moneyIn,
            setValue: setMoneyIn,
            refresh: refreshMoneyIn
        },
        colors: {
            value: colors,
            setValue: setColors
        },
        stats: {
            value: stats,
            setValue: setStats,
            refreshStats
        },
        recurrencyPeriods: {
            value: recurrencyPeriods,
            setValue: setRecurrencyPeriods,
            refreshRecurrencyPeriods
        },
        showTutorial: {
            value: showTutorial,
            setValue: (value = false) => {
                setShowTutorial(value)
                SecureStoragePlugin.set({
                    key: "show-tutorial",
                    value : value
                })
            }
        }
    }



    return (
        <AppContext.Provider
            value={contextValue}
        >
            {children}
        </AppContext.Provider>
    )
}