import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import { CategoryModel } from "../../../../../../../Models/CategoryModel";
import {useCallback, useEffect, useState } from "react";
import IconSelector from "./IconSelector";
import { Delete } from "@mui/icons-material";
import ColorSelector from "./ColorSelector";


export default function CategoryEditDialog({ open, onClose, category = { ...CategoryModel }, onSave, onDelete, token }) {

    const [categoryForm, setCategoryForm] = useState({ ...CategoryModel, color_id: null });

    useEffect(() => {
        setCategoryForm({
            color_id: category.color.color_id,
            description: category.description,
            expense_category_id: category.expense_category_id,
            icon_id: category.icon_id,
            user_id: category.user_id,
            weekly_view: category.weekly_view,
            default_budget: category.default_budget
        })
    }, [open])

    const TitleRender = useCallback(() => {
        if (category.expense_category_id === 0) {
            return (
                <div className="dialog-title">
                    Aggiungi Categoria
                </div>
            )
        }

        return (
            <div className="dialog-title-with-button">
                {category.description}
                <IconButton onClick={onDelete} >
                    <Delete className='icon-size' fontSize="medium" color="error" />
                </IconButton>
            </div>
        )
    }, [category])

    const onValueChange = useCallback(e => {
        setCategoryForm(old => ({ ...old, [e.target.name]: e.target.value }))
    }, [])

    const onChangeIcon = useCallback((newIconIndex) => {
        setCategoryForm((old) => ({ ...old, icon_id: newIconIndex }))
    }, [])

    const onChangeColor = useCallback((newColorId) => {
        setCategoryForm(old => ({ ...old, color_id: newColorId }));
    }, [])

    const onSaveClickHandler = useCallback(() => {
        if (categoryForm.color_id === 0) {
            alert("Seleziona un colore.");
            return;
        }
        const body = {
            description: categoryForm.description,
            color_id: categoryForm.color_id,
            icon_id: categoryForm.icon_id,
            default_budget : categoryForm.default_budget
        };

        onSave(body)
    }, [categoryForm])

    return (
        <Dialog
            className="help-dialog"
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle
                className="help-dialog-title"
            >
                <TitleRender />
            </DialogTitle>
            <DialogContent
                sx={{ display: "flex", flexDirection: "column", gap: "2vh" }}
            >
                <TextField
                    className="text-field-fonts"
                    type="text"
                    fullWidth
                    name="description"
                    value={categoryForm.description}
                    label="Descrizione"
                    onChange={onValueChange}
                    sx={{ marginTop: "1.5vh" }}
                />
                <IconSelector
                    selectedIcon={categoryForm.icon_id}
                    onSelectionChange={onChangeIcon}
                />

                <ColorSelector
                    token={token}
                    onSelectedColorChange={onChangeColor}
                    selectedColor={categoryForm.color_id}
                />

                <TextField
                    className="text-field-fonts"
                    type="number"
                    fullWidth
                    name="default_budget"
                    value={categoryForm.default_budget === 0 ? "" : categoryForm.default_budget}
                    label="Budget di default"
                    onChange={onValueChange}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    className="button"
                    variant="contained"
                    color="error"
                    onClick={onClose}
                >
                    Annulla
                </Button>
                <Button
                    className="button"
                    variant="contained"
                    color="success"
                    onClick={onSaveClickHandler}
                >
                    {categoryForm.expense_category_id === 0 ? "Aggiungi" : "Modifica"}
                </Button>
            </DialogActions>
        </Dialog>

    )
}