import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import HelpDialog from "../../../Components/HelpDialogs/HelpDialog";
import HelpContentStatsSimulation from "../../../HelpContents/HelpContentStatsSimulation/HelpContentStatsSimulation";
import HelpIcon from '@mui/icons-material/Help';
import StatsDetails from "./StatsDetails";

export default function StatsSavingsSimulations({ open, onClose, averageYearBalance }) {

    const [savingMonths, setSavingMonths] = useState("")
    const [wantedSavingForm, setWantedSavingForm] = useState({
        months: "",
        saving: ""
    })

    const [showHelpSimulation, setShowHelpSimulation] = useState(false)
    const [showStatsDetails, setShowStatsDetails] = useState(false)

    const onCloseWrapper = useCallback(() => {
        onClose();
        setSavingMonths("")
        setWantedSavingForm({
            months: "",
            saving: ""
        })
    }, [onClose, setSavingMonths, setWantedSavingForm])

    const onUpdateWantedSavingForm = useCallback((e) => {
        setWantedSavingForm(old => ({
            ...old,
            [e.target.name]: e.target.value === "" ? "" : parseFloat(e.target.value)
        }))
    }, [])

    const wantedSaving = useMemo(() => {
        if (wantedSavingForm.months !== "" && wantedSavingForm.saving !== "") {
            return wantedSavingForm.saving / wantedSavingForm.months
        }
        return null
    }, [wantedSavingForm])

    return (
        <Dialog
            className="help-dialog"
            open={open}
            onClose={onCloseWrapper}
            fullScreen
        >
            <DialogTitle
                className="help-dialog-title"
            >
                Strumento di simulazione
                <IconButton
                    onClick={() => setShowHelpSimulation(true)}
                >
                    <HelpIcon className='icon-size' />
                </IconButton>
                <HelpDialog
                    onClose={() => setShowHelpSimulation(false)}
                    showHelp={showHelpSimulation}
                >
                    <HelpContentStatsSimulation />
                </HelpDialog>
            </DialogTitle>
            <DialogContent
                sx={{
                    paddingTop: "2vh !important",
                    background: "#4169e1bf"
                }}
            >
                <div
                    className="summary-money-in-total-container"
                >
                    <div className="summary-money-in-total-title">Risparmio probabile</div>
                    <TextField
                        className="text-field-fonts"
                        type="number"
                        label="Numero di mesi"
                        variant="outlined"
                        fullWidth
                        sx={{
                            marginTop: "2vh"
                        }}
                        value={savingMonths}
                        onChange={e => {
                            if (e.target.value === "") {
                                setSavingMonths("")
                            }
                            else {
                                setSavingMonths(parseInt(e.target.value));
                            }
                        }}
                    />

                    <div
                        style={{
                            height: "8vh",
                            padding: "1vh",
                            fontWeight: "bold"
                        }}
                    >
                        {
                            savingMonths !== "" ?
                                <>
                                    Mantenendo questa media è atteso un saldo totale di:
                                    <div
                                        style={{
                                            textAlign: "right",
                                            fontSize: "6vw",
                                            fontWeight: "bolder"
                                        }}
                                    >
                                        {(averageYearBalance * savingMonths).toLocaleString("it-IT", { style: "currency", currency: "EUR" })}
                                    </div>
                                </>
                                :
                                <>
                                    Inserisci un valore sopra per ottenere il conteggio
                                </>
                        }
                    </div>


                </div>

                <div
                    className="summary-money-in-total-container"
                    style={{
                        marginTop: "2vh"
                    }}
                >
                    <div className="summary-money-in-total-title">Saldo Desiderato</div>
                    <TextField
                        className="text-field-fonts"
                        type="number"
                        label="Numero di mesi"
                        variant="outlined"
                        fullWidth
                        sx={{
                            marginTop: "2vh"
                        }}
                        value={wantedSavingForm.months}
                        onChange={onUpdateWantedSavingForm}
                        name="months"
                    />
                    <TextField
                        className="text-field-fonts"
                        type="number"
                        label="Importo"
                        variant="outlined"
                        fullWidth
                        sx={{
                            marginTop: "2vh"
                        }}
                        value={wantedSavingForm.saving}
                        onChange={onUpdateWantedSavingForm}
                        name="saving"
                    />

                    <div
                        style={{
                            minHeight: "8vh",
                            padding: "1vh",
                            fontWeight: "bold"
                        }}
                    >
                        {
                            wantedSavingForm.months !== "" && wantedSavingForm.saving !== "" ?
                                <>
                                    La media necessaria per ottenere quel saldo è:
                                    <div
                                        style={{
                                            textAlign: "right",
                                            fontSize: "6vw",
                                            fontWeight: "bolder"
                                        }}
                                    >
                                        {(wantedSaving).toLocaleString("it-IT", { style: "currency", currency: "EUR" })}
                                    </div>
                                    {
                                        wantedSaving > averageYearBalance &&
                                        <>
                                            <div
                                                style={{
                                                    marginTop: "1vh"
                                                }}
                                            >
                                                Al momento non sei in grado di conseguire questo obiettivo
                                            </div>
                                            <Button
                                                className="button"
                                                variant="contained"
                                                sx={{
                                                    marginTop: "1vh"
                                                }}
                                                onClick={() => setShowStatsDetails(true)}
                                            >
                                                Clicca per ottenere un analisi dettagliata
                                            </Button>
                                            <StatsDetails open={showStatsDetails} onClose={() => setShowStatsDetails(false)} />
                                        </>

                                    }
                                </>
                                :
                                <>
                                    Compila i dati per ottenere il conteggio
                                </>
                        }
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    className="button"
                    onClick={onCloseWrapper}
                    variant="contained"
                >
                    Chiudi
                </Button>
            </DialogActions>
        </Dialog>
    )
}