import realClosingImg from "../../../assets/summary/mensile-reale.png";
import HelpContentStyledPar from "./HelpContentsStyledPar";
import realClosingChart from "../../../assets/summary/grafico_andamento_chiusure.png";

export default function HelpSummary({ }) {
    return (
        <div>
            <p>
                La sezione <b>Riepilogo</b> fornisce una visione generica del mese selezionato
            </p>
            <p>
                L'unica interazione disponibile è nella zona <b>Chiusura Mensile Reale</b> dove è possibile indicare
                l'importo (il saldo in nostro possesso) del mese in questione, a partire dal mese successivo. <br />
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={realClosingImg}
                />
            </HelpContentStyledPar>

            <p>Questa Informazione è utile per sapere l'andamento globale della situazione finanziaria nella sezione <b>Statistiche</b>, da qui sarà possibile sapere se la salute finanziaria è globalmente cresciuta o diminuita</p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={realClosingChart}
                />
            </HelpContentStyledPar>
        </div>
    )
}