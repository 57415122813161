import { Icons } from "../../../../../../../Models/Icons";

export default function IconSelector({ selectedIcon, onSelectionChange }) {
    return (
        <div className="icon-selector-container">
            {Icons.map((Icon, i) => <div
                className={`icon-selector-item ${selectedIcon === i ? "selected" : ""}`}
                onClick={() => onSelectionChange(i)}
            >
                <Icon fontSize="large" />
            </div>)}
        </div>
    )
}