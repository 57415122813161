import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { RecurrencyPeriodsModel } from "../../../../../../../Models/RecurrencyPeriodsModel";
import { useCallback, useContext, useEffect, useState } from "react";
import { RecurrencyPeriodsAPI } from "../../../../../../../RemoteAPI/RecurrencyPeriods";
import { AppContext } from "../../../../../../../Context/AppContext";
import { errorNotification, successNotification } from "../../../../../../../utils";

const FormDefaultValue = {
    description: "",
    repetition_days: "",
    repetition_count: "",
    selectedRecurrenceType: "monthly"
}

export default function RecurrencyPeriodsEditDialog({ open, onClose, recurrencyPeriod = { ...RecurrencyPeriodsModel } }) {

    const [form, setForm] = useState(FormDefaultValue)

    const AppData = useContext(AppContext)


    const onFormValueChange = useCallback((e) => {
        setForm(old => ({ ...old, [e.target.name]: isNaN(e.target.value) ? e.target.value : parseInt(e.target.value) }))
    }, [setForm])

    useEffect(() => {
        if (recurrencyPeriod.recurrent_period_id !== 0) {
            setForm({
                ...recurrencyPeriod,
                selectedRecurrenceType: recurrencyPeriod.repetition_days !== 0 ? "custom" : "monthly"
            })
        }
        else {
            setForm(FormDefaultValue)
        }

    }, [open])

    const onSave = useCallback(async () => {
        const body = { ...form };
        delete body.selectedRecurrenceType;
        delete body.recurrent_period_id;

        try {
            let result = null;
            if (recurrencyPeriod.recurrency_period_id !== 0) {
                result = await RecurrencyPeriodsAPI.update.updateRecurrencyPeriod(AppData.authentication.value.token, recurrencyPeriod.recurrency_period_id, body)
            }
            else {
                result = await RecurrencyPeriodsAPI.post.createRecurrencyPeriod(AppData.authentication.value.token, body);
            }

            const response = await result.json();

            if (result.status !== 201 && result.status !== 200) {
                errorNotification(response.result.description)
            }
            else {
                successNotification(response.result.description)
                AppData.recurrencyPeriods.refreshRecurrencyPeriods()
                onClose();
            }
        } catch (error) {

        }
    }, [form, AppData.recurrencyPeriods.refreshRecurrencyPeriods])


    return (
        <Dialog
            className="help-dialog"
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle
                className="help-dialog-title"
            >
                {recurrencyPeriod.recurrent_period_id !== 0 ? recurrencyPeriod.description : "Nuovo periodo"}
            </DialogTitle>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3vh",
                    paddingTop: "1vh !important"
                }}
            >
                <TextField
                    className="text-field-fonts"
                    value={form.description}
                    onChange={onFormValueChange}
                    label="Descrizione"
                    name="description"
                    fullWidth
                />
                <FormControl>
                    <InputLabel id="rec-type-label">Tipo Ricorrenza</InputLabel>
                    <Select
                        labelId="rec-type-label"
                        id="rec-type"
                        label="Tipo Ricorrenza"
                        value={form.selectedRecurrenceType}
                        onChange={(e) => setForm(old => ({ ...old, selectedRecurrenceType: e.target.value }))}
                    >
                        <MenuItem value={"monthly"}>Mensile</MenuItem>
                        <MenuItem value={"custom"}>Custom</MenuItem>
                    </Select>
                </FormControl>

                <div
                    style={{
                        display: "flex"
                    }}
                >

                    {
                        form.selectedRecurrenceType === "custom" && <>
                            <TextField
                                className="text-field-fonts"
                                label="Giorni"
                                fullWidth
                                value={form.repetition_days}
                                name="repetition_days"
                                onChange={onFormValueChange}
                                type="number"
                            />
                            <div className="expense-edit-dialog-multiply">X</div>
                        </>
                    }
                    <TextField
                        className="text-field-fonts"
                        label={form.selectedRecurrenceType === "custom" ? "Volte" : "Mesi"}
                        fullWidth
                        value={form.repetition_count}
                        name="repetition_count"
                        onChange={onFormValueChange}
                        type="number"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    className="button"
                    variant="contained"
                    color="error"
                    onClick={onClose}
                >
                    Annulla
                </Button>
                <Button
                    className="button"
                    variant="contained"
                    color="success"
                    onClick={onSave}
                >
                    Salva
                </Button>
            </DialogActions>
        </Dialog>
    )
}