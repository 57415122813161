import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getTextFieldStyle } from "../../../../../utils";

export default function SetExpenseCategoryBudgetDialog({ currentBudget, onModify, open, onClose }) {
    
    const [budgetInput, setBudgetInput] = useState(currentBudget)

    const onCloseWrapper = useCallback(() => {
        setBudgetInput(currentBudget);
        onClose()
    }, [currentBudget, onClose])
    return (
        <Dialog
            className="help-dialog"
            open={open}
            onClose={onCloseWrapper}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle
                className="help-dialog-title"
            >
                Budget Mensile
            </DialogTitle>
            <DialogContent
                sx={{ display: "flex", flexDirection: "column", gap: "2vh" }}
            >
                <TextField
                    className="text-field-fonts"
                    type="text"
                    fullWidth
                    name="budget"
                    value={budgetInput}
                    label="Budget"
                    onChange={e => setBudgetInput(e.target.value)}
                    sx={{ marginTop: "1.5vh" }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    className="button"
                    variant="contained"
                    color="error"
                    onClick={onCloseWrapper}
                >
                    Annulla
                </Button>
                <Button
                    className="button"
                    variant="contained"
                    color="success"
                    onClick={() => onModify(budgetInput)}
                >
                    Modifica
                </Button>
            </DialogActions>
        </Dialog>
    )
}