export default function HelpContentStyledPar({children}) {
    return (
        <p
            style={{
                display: "flex",
                justifyContent: "center",
                boxShadow: "lightgray 0px 0px 3px 1px",
                padding : "0.5vh 0"
            }}
        >
            {children}
        </p>
    )
}