import { API_URL } from "./config"

function authenticate(username, password) {
    const body = {
        email: username, password
    }
    return fetch(
        `${API_URL}/users/authenticate`,
        {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json"
            }
        }
    )
}

function refreshToken(refreshToken) {
    return fetch(`${API_URL}/users/authenticate/refresh`,
        {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${refreshToken}`
            }
        })
}

function getUserYears(token) {
    return fetch(`${API_URL}/users/visibleyears`,
        {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
}

function getCurrentUserData(token) {
    return fetch(`${API_URL}/users/current`,
        {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
}

function updateCurrentUser(token, body = { name: "", surname: "" }) {
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
    }

    return fetch(`${API_URL}/users/current`,
        {
            method: "PUT",
            headers,
            body: JSON.stringify(body)
        })
}

function changePassword(token, body = { old_password: "", new_password: "" }) {
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
    }

    return fetch(`${API_URL}/users/changepassword`,
        {
            method: "PUT",
            headers,
            body: JSON.stringify(body)
        })
}

function deleteCurrentUser(token) {
    const headers = {
        "Authorization": `Bearer ${token}`
    }

    return fetch(`${API_URL}/users/current`,
        {
            method: "DELETE",
            headers
        })
}

function createUser(email, name, surname, password) {
    const body = {
        email, name, surname, password
    }

    const headers = {
        "Content-Type" : "application/json"
    }

    return fetch(`${API_URL}/users`, {
        method: "POST",
        headers: headers,
        body : JSON.stringify(body)
    })   
}


export const UsersAPI = {
    get: {
        getUserYears,
        getCurrentUserData
    },
    post: {
        authenticate,
        refreshToken,
        createUser
    },
    put: {
        updateCurrentUser,
        changePassword
    },
    delete: {
        deleteCurrentUser
    }
}