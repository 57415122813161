import { API_URL } from "./config";

function getStats(token, selectedYear, selectedMonth) {
    const headers = {
        "Authorization" : `Bearer ${token}`
    }
    return fetch(`${API_URL}/stats/${selectedYear}/${selectedMonth}`,
        {
            method: "GET",
            headers : headers
        }
    ) 
}

function getStatsDetails(token, selectedYear) {
    const headers = {
        "Authorization" : `Bearer ${token}`
    }
    return fetch(`${API_URL}/stats/${selectedYear}/details`,
        {
            method: "GET",
            headers : headers
        }
    ) 
}

function setMonthlyClosing(token, selectedYear, selectedMonth, closing) {
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type" : "application/json"
    }
    const body = {
        closing
    }
    return fetch(`${API_URL}/stats/${selectedYear}/${selectedMonth}/closing`,
        {
            method: "POST",
            headers: headers,
            body : JSON.stringify(body)
        }
    )     
}

export const SummaryAPI = {
    post: {
        setMonthlyClosing
    },
    get: {
        getStats,
        getStatsDetails
    }
}