import HelpContentStyledPar from "../HelpContentsStyledPar";
import groupExpense from "../../../../assets/expenses/edit_expense/raggruppa_per_data.png" 
import listMode from "../../../../assets/expenses/edit_expense/raggruppamento_per_data.png"
import editExpense from "../../../../assets/expenses/edit_expense/tasto_modifica_spesa.png"
import descriptionButton from "../../../../assets/expenses/edit_expense/tasto_descrizione.png"
import deleteButton from "../../../../assets/expenses/edit_expense/tasto_elimina.png"
import EditIcon from '@mui/icons-material/Edit';
import NotesIcon from '@mui/icons-material/Notes';
import DeleteIcon from '@mui/icons-material/Delete';

export default function HelpContentExpensesEditExpense() {
    return (
        <>
            <h3>Raggruppamento</h3>
            <p>
                E' possibile modificare la visualizzazione della lista spese tramite il pulsante <b>Raggruppa per data</b>. Questa visualizzazione
                raggrupperà le spese per la data di riferimento.
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={groupExpense}
                />
            </HelpContentStyledPar>
            <p>
                Per ogni giorno verrà visualizzato il totale delle spese effettuate. Sarà possibile tornare alla visualizzazione precedente cliccando 
                sul pulsante <b>Lista</b>
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={listMode}
                />
            </HelpContentStyledPar>
            <h3>Modifica Spesa</h3>
            <p>
                Per modificare una spesa è sufficiente cliccare sul tasto <EditIcon /> e modificare i dati precompilati.
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={editExpense}
                />
            </HelpContentStyledPar>
            <h3>Descrizione</h3>
            <p>
                Premendo il tasto <NotesIcon /> è possibile leggere la descrizione (se compilata).
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={descriptionButton}
                />
            </HelpContentStyledPar>
            <h3>Eliminazione</h3>
            <p>
                Premendo il tasto <DeleteIcon /> è possibile eliminare la spesa.
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={deleteButton}
                />
            </HelpContentStyledPar>
        </>
    )
}