import HelpContentStyledPar from "../HelpContentsStyledPar";
import expensesScreen from "../../../../assets/expenses/first/uscite.png";
import remainingBudget from "../../../../assets/expenses/first/budget_residuo.png";

export default function HelpContentExpensesFirst() {
    return (
        <>
            <p>
                Nella sezione <b>Uscite</b> potrai inserire tutte le spese che effettuerai giornalmente.
                Inserire i dati in questa sezione è fondamentale per la generazione delle statistiche utili a capire la propria situazione finanziaria.
            </p>
            <p>
                Le spese verranno suddivise in base alle categorie inserite nella sezione <b>Impostazioni</b>
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={expensesScreen}
                />
            </HelpContentStyledPar>
            <p>
                Inoltre è possibile verificare il budget mensile residuo.
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={remainingBudget}
                />
            </HelpContentStyledPar>
            
        </>
    )
}