import { API_URL } from "./config"

function getExpensesCategories(token) {

    const headers = {
        "Authorization" : `Bearer ${token}`
    }

    return fetch(`${API_URL}/expensecategories`,
        {
            method: "GET",
            headers : headers
        }
    )
}

function createExpensesCategories(token, body) {

    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type" : "application/json"
    }

    return fetch(`${API_URL}/expensecategories`,
        {
            method: "POST",
            headers: headers,
            body : JSON.stringify(body)
        }
    )
}

function updateExpensesCategories(token, expense_category_id, body) {

    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type" : "application/json"
    }

    return fetch(`${API_URL}/expensecategories/${expense_category_id}`,
        {
            method: "PUT",
            headers: headers,
            body : JSON.stringify(body)
        }
    )
}

function deleteExpenseCategorie(token, expense_category_id) {
    const headers = {
        "Authorization": `Bearer ${token}`,
    }

    return fetch(`${API_URL}/expensecategories/${expense_category_id}`,
        {
            method: "DELETE",
            headers: headers
        }
    )
}

function setMonthlyBudget(token, expense_category_id, body = { ref_month : 0, ref_year : 0, budget : 0 }) {
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type" : "application/json"
    }

    return fetch(`${API_URL}/expensecategories/${expense_category_id}/monthlybudget`,
        {
            method: "POST",
            headers: headers,
            body : JSON.stringify(body)
        }
    )
}

function setOrder(token, body = []) {
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type" : "application/json"
    }

    return fetch(`${API_URL}/expensecategories/setorder`,
        {
            method: "PUT",
            headers: headers,
            body: JSON.stringify({
                sorted_category_ids : body
            })
        }
    )
}

export const ExpensesCategoriesAPI = {
    get: {
        getExpensesCategories
    },
    post: {
        createExpensesCategories,
        setMonthlyBudget
    },
    put: {
        updateExpensesCategories,
        setOrder
    },
    delete: {
        deleteExpenseCategorie
    }
}