import { useCallback, useState } from "react";
import { CategoryModel } from "../../../../../../Models/CategoryModel";
import { Icons } from "../../../../../../Models/Icons";
import CategoryEditDialog from "./Dialogs/CategoryEditDialog";
import { errorNotification, successNotification } from "../../../../../../utils";
import { ExpensesCategoriesAPI } from "../../../../../../RemoteAPI/ExpensesCategories";

export default function CategoryItem({ categoryExpense = ({ ...CategoryModel }), token, getExpensesCategories, refreshExepenses }) {

    const [showEditDialog, setShowEditDialog] = useState(false)
    const Icon = Icons[categoryExpense.icon_id];

    const updateCategory = useCallback(async (body) => {
        try {
            const result = await ExpensesCategoriesAPI.put
                .updateExpensesCategories(token, categoryExpense.expense_category_id, body);
            const response = await result.json();
            if (result.status !== 200) {
                errorNotification(response.result.description)
                return
            }
            successNotification(response.result.description);
            setShowEditDialog(false)
            getExpensesCategories();
            refreshExepenses()
        } catch (error) {
            console.log(error);
            errorNotification("Errore del server.");
        }
    }, [categoryExpense, token, getExpensesCategories])

    const deleteCategory = useCallback(async () => {
        try {
            const result = await ExpensesCategoriesAPI.delete
                .deleteExpenseCategorie(token, categoryExpense.expense_category_id);

            const response = await result.json();

            if (result.status !== 200) {    
                errorNotification(response.result.description)
                return
            }

            successNotification(response.result.description);
            setShowEditDialog(false)
            getExpensesCategories()
            refreshExepenses()
        } catch (error) {
            console.log(error);
            errorNotification("Errore del server.");
        }
    }, [categoryExpense, token, getExpensesCategories])

    return (
        <>
            <div
                className="category-item"
                style={{
                    backgroundColor: categoryExpense.color !== null && categoryExpense.color.color_id !== 0 ? categoryExpense.color.hex_code : "white"
                }}
                onClick={() => setShowEditDialog(true)}
            >
                {
                    categoryExpense.default_budget !== 0 &&
                    <div
                        className="category-item-budget-badge"
                    >
                        {categoryExpense.default_budget.toLocaleString("it-IT", { style: "currency", currency: "EUR" })}
                    </div>
                }
                <Icon
                    className="settings-category-item-icon"
                />
                {categoryExpense.description}
            </div>
            {
                showEditDialog &&
                <CategoryEditDialog
                    open={showEditDialog}
                    onClose={() => setShowEditDialog(false)}
                    onDelete={deleteCategory}
                    category={categoryExpense}
                    onSave={updateCategory}
                    token={token}
                />
            }
        </>

    )
}