import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import { MoneyInModel } from "../../../../../Models/MoneyInModel";
import { useCallback, useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";


export default function EditMoneyInDialog({ open, onClose, moneyin = MoneyInModel, onSave, onDelete }) {

    const [moneyInForm, setMoneyInForm] = useState({
        money_in_id: moneyin.money_in_id,
        description: moneyin.description,
        amount: moneyin.amount
    })

    const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false)

    const onValueChange = useCallback((e) => {
        setMoneyInForm(old => ({ ...old, [e.target.name]: e.target.value }))
    }, [setMoneyInForm])

    useEffect(() => {
        setMoneyInForm({
            amount: moneyin.amount,
            description: moneyin.description,
            money_in_id: moneyin.money_in_id
        })
    }, [open])

    const onOpenDeleteItemDialog = useCallback(() => {
        setOpenDeleteItemDialog(true)
    }, [setOpenDeleteItemDialog])

    const onCloseDeleteItemDialog = useCallback(() => {
        setOpenDeleteItemDialog(false)
    }, [setOpenDeleteItemDialog])

    const onSaveClickHandler = useCallback(() => {

        onSave({ description: moneyInForm.description, amount: moneyInForm.amount })
        onClose()

    }, [moneyInForm, onClose, onSave])


    const onDeleteClickHandler = useCallback(() => {

        onDelete()
        onCloseDeleteItemDialog()
        onClose()

    }, [moneyInForm, onClose, onDelete, onCloseDeleteItemDialog])


    const TitleRender = useCallback(() => {
        if (moneyin.money_in_id === 0) {
            return (
                <div className="dialog-title">
                    Aggiungi Entrate
                </div>
            )
        }

        return (
            <div className="dialog-title-with-button">
                {moneyin.description}
                <IconButton onClick={onOpenDeleteItemDialog} >
                    <Delete className='icon-size' fontSize="medium" color="error" />
                </IconButton>
            </div>
        )
    }, [moneyin, onOpenDeleteItemDialog])

    return (
        <>
            <Dialog
                className="help-dialog"
                open={open}
                onClose={onClose}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle
                    className="help-dialog-title"
                >
                    <TitleRender />
                </DialogTitle>
                <DialogContent
                    sx={{ display: "flex", flexDirection: "column", gap: "2vh" }}
                >
                    <TextField
                        className="text-field-fonts"
                        fullWidth
                        name="description"
                        value={moneyInForm.description}
                        label="Descrizione"
                        sx={{ marginTop: "1.5vh" }}
                        onChange={onValueChange}
                    />
                    <TextField
                        className="text-field-fonts"
                        sx={{ textAlign: "right" }}
                        type="number"
                        fullWidth
                        name="amount"
                        value={moneyInForm.amount === 0 ? "" : moneyInForm.amount}
                        label="Importo"
                        onChange={onValueChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button"
                        variant="contained"
                        color="error"
                        onClick={onClose}
                    >
                        Annulla
                    </Button>
                    <Button
                        className="button"
                        variant="contained"
                        color="success"
                        onClick={onSaveClickHandler}
                    >
                        {moneyin.money_in_id === 0 ? "Aggiungi" : "Modifica"}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                className="help-dialog"
                open={openDeleteItemDialog}
                onClose={onCloseDeleteItemDialog}
                maxWidth="sm"
            >
                <DialogTitle
                    className="help-dialog-title"
                >
                    <div className="dialog-title">Elimina</div>
                </DialogTitle>
                <DialogContent
                    sx={{ display: "flex", flexDirection: "column", gap: "2vh" }}
                >
                    <Typography>
                        <h3>
                            Sei sicuro di voler eliminare l'entrata?
                        </h3>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button"
                        variant="contained"
                        color="primary"
                        onClick={onCloseDeleteItemDialog}
                    >
                        Annulla
                    </Button>
                    <Button
                        className="button"
                        variant="contained"
                        color="error"
                        onClick={onDeleteClickHandler}
                    >
                        Elimina
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}