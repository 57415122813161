import { useContext} from "react"
import { SyncLoader} from "react-spinners";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AppContext } from "../../../../../../../Context/AppContext";

export default function ColorSelector({ selectedColor, onSelectedColorChange, token }) {    
    const AppData = useContext(AppContext);
    
    if (AppData.colors.value.length === 0 || (AppData.colors.value.length === 1 && AppData.colors.value[0].color_id === 0)) {
        return (
            <div
                style={
                    {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "10vh"
                    }}
            >
                <SyncLoader />
            </div>
        )
    }

    return (
        <div
            className="color-selector-container"
        >
            {
                AppData.colors.value.map(color => <div
                    className={`color-selector-item`}
                    style={{ background: color.hex_code }}
                    onClick={() => onSelectedColorChange(color.color_id)}
                >
                    {color.color_id === selectedColor && <CheckCircleIcon fontSize="large" />}
                </div>
                )
            }
        </div>
    )
}