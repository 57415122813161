import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { AppContext } from "../../../../Context/AppContext"
import "./summary.css";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, TextField } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import { SummaryAPI } from "../../../../RemoteAPI/Summary";
import { errorNotification, successNotification, toggleLoadingOverlay } from "../../../../utils";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Chart } from "react-google-charts";
import ShowChartIcon from '@mui/icons-material/ShowChart';

export default function Summary({ }) {


    const [openSetClosingDialog, setOpenSetClosingDialog] = useState(false);
    const [openExpectedSavingDialogChart, setOpenExpectedSavingDialogChart] = useState(false);


    const AppData = useContext(AppContext);

    console.log("AppData.stats.value", AppData.stats.value)

    const moneyInTotal = useMemo(() => {
        return AppData.moneyIn.value.reduce((sum, moneyIn) => sum += moneyIn.amount, 0);
    }, [AppData])

    const expenseStats = useMemo(() => {
        let total = 0;
        let mostUsedCategory = {
            catTotal: 0,
            description: ""
        };
        AppData.expenses.value.forEach(category => {
            const catTotal = category.expenses.reduce((sum, expense) => sum += expense.amount, 0)
            if (mostUsedCategory.catTotal < catTotal) {
                mostUsedCategory = {
                    catTotal: catTotal,
                    description: category.description
                }
            }
            total += catTotal;
        })

        return {
            total: total,
            mostUsedCategory
        };
    }, [AppData])

    const expenseTotalOnMoneyInPercentage = useMemo(() => {
        if (moneyInTotal === 0)
            return 0;
        return (expenseStats.total / moneyInTotal) * 100
    }, [moneyInTotal, expenseStats])

    const dailyMoneyOut = useMemo(() => {
        const days = new Date(AppData.selectedYear.value, AppData.selectedMonth.value + 1, 0).getDate();

        return expenseStats.total / days;

    }, [expenseStats, AppData.selectedMonth, AppData.selectedYear])

    const closingAvailability = useMemo(() => {
        let nextMonth = AppData.selectedMonth.value + 2;
        let nextYear = AppData.selectedYear.value;

        if (nextMonth > 12) {
            nextMonth = 1;
            nextYear += 1;
        }
        console.log(nextMonth, nextYear)
        const date = new Date(`${nextYear}-${nextMonth < 10 ? `0${nextMonth}` : nextMonth}-01`);
        const referencePeriod = new Date(`${AppData.selectedYear.value}-${(AppData.selectedMonth.value + 1) < 10 ? `0${AppData.selectedMonth.value + 1}` : AppData.selectedMonth.value + 1}-01`);
        const curDate = new Date();
        let canSet = curDate >= date;

        let monthDescription = date.toLocaleString("it-IT", { month: "long" });
        monthDescription = `${monthDescription.charAt(0).toUpperCase()}${monthDescription.slice(1, monthDescription.length)}`

        let thisMonthDescription = referencePeriod.toLocaleString("it-IT", { month: "long" });
        thisMonthDescription = `${thisMonthDescription.charAt(0).toUpperCase()}${thisMonthDescription.slice(1, thisMonthDescription.length)}`
        return {
            canSet,
            availableFrom: `${monthDescription} ${nextYear}`,
            referenceMonthDescription: thisMonthDescription
        }

    }, [AppData.selectedMonth.value, AppData.selectedYear.value])


    const onSaveClosing = useCallback(async () => {
        toggleLoadingOverlay()
        const closingValue = parseFloat(document.getElementById("month-closing-input-value").value)
        try {
            const result = await SummaryAPI.post.setMonthlyClosing(AppData.authentication.value.token, AppData.selectedYear.value, AppData.selectedMonth.value + 1, closingValue)
            const response = await result.json()

            if (result.status === 200) {
                successNotification(response.result.description)
                AppData.expenses.refresh()
                setOpenSetClosingDialog(false)
            }
            else {
                errorNotification(response.result.description)
            }
            toggleLoadingOverlay()
        } catch (error) {
            errorNotification("Errore del server")
            toggleLoadingOverlay()
        }
    }, [AppData.selectedMonth.value, AppData.selectedYear.value, AppData.authentication.value.token, setOpenSetClosingDialog])

    console.log(closingAvailability);

    return (
        <div className="summary-container">
            <div
                className="summary-money-in-total-container"
            >
                <div className="summary-money-in-total-title">Entrate</div>
                <div className="summary-money-in-total-amount">{moneyInTotal.toLocaleString("it-IT", { currency: "EUR", style: "currency" })}</div>
            </div>

            <div
                className="summary-money-in-total-container"
            >
                <div className="summary-money-in-total-title">Uscite</div>
                <div className="summary-money-in-total-amount"

                >
                    {expenseStats.total.toLocaleString("it-IT", { currency: "EUR", style: "currency" })}
                </div>

                <LinearProgress
                    value={expenseTotalOnMoneyInPercentage}
                    variant="determinate"
                    sx={{
                        height: "2vh"
                    }}
                />

                <div className="summary-money-in-out-linear-progress" style={{ fontSize: "4vw", marginBottom: "1vh" }}>{expenseTotalOnMoneyInPercentage.toFixed(1)}%</div>
            </div>

            <div
                className="summary-money-in-total-container"
            >
                <div
                    className="summary-money-in-total-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    Saldo
                </div>
                <div
                    className="summary-money-in-total-amount"
                    style={{
                        color: expenseStats.total > moneyInTotal ? "red" : "green"
                    }}
                >
                    {(moneyInTotal - expenseStats.total).toLocaleString("it-IT", { currency: "EUR", style: "currency" })}
                </div>

                <div className="summary-money-in-total-title">Saldo stimato</div>
                <div className="summary-money-in-total-amount">{AppData.stats.value?.expected_saving?.toLocaleString("it-IT", { currency: "EUR", style: "currency" })}</div>
            </div>




            <div
                className="summary-money-in-total-container"
            >
                <div className="summary-money-in-total-title">Categoria più utilizzata</div>
                <div
                    className="summary-money-in-total-amount"
                    style={{
                        textAlign : "left",
                        marginTop: "1vh",
                        marginBottom: "1vh"
                    }}
                >
                    {expenseStats.mostUsedCategory.description === "" ? "Nessuna" : expenseStats.mostUsedCategory.description}
                </div>
            </div>

            <div
                className="summary-money-in-total-container"
            >
                <div className="summary-money-in-total-title">Spesa Giornaliera</div>
                <div className="summary-money-in-total-amount">{dailyMoneyOut.toLocaleString("it-IT", { currency: "EUR", style: "currency" })}</div>
            </div>

            <div
                className="summary-money-in-total-container"
            >
                <div
                    className="summary-money-in-total-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    Chiusura Mensile Reale
                    {closingAvailability.canSet && closingAvailability.canSet &&
                        <IconButton
                            onClick={() => setOpenSetClosingDialog(true)}
                        >
                            <SettingsIcon
                                className='icon-size'
                                sx={{
                                    fontSize: "7vw"
                                }}
                            />
                        </IconButton>
                    }


                </div>
                <div className="summary-money-closing-container">
                    {closingAvailability.canSet && AppData.stats.value.month_closing && <div
                        className="summary-money-closing-grow"
                        style={{
                            color: AppData.stats.value.month_closing_grow >= 0 ? "green" : "red"
                        }}
                    >
                        {AppData.stats.value.month_closing_grow > 0 && <ArrowUpwardIcon />}
                        {AppData.stats.value.month_closing_grow < 0 && <ArrowDownwardIcon />}
                        {parseFloat(AppData.stats.value.month_closing_grow).toFixed(2)}
                    </div>}

                    {closingAvailability && !closingAvailability.canSet &&
                        <div
                            className="summary-month-closing-descriptions"
                        >
                            Inseribile da {closingAvailability.availableFrom}
                        </div>
                    }
                    {closingAvailability.canSet && AppData.stats.value.month_closing && AppData.stats.value.month_closing.toLocaleString("it-IT", { currency: "EUR", style: "currency" })}
                    {closingAvailability.canSet && !AppData.stats.value.month_closing && <div className="summary-month-closing-descriptions">Non inserita</div>}
                </div>


                {closingAvailability.canSet &&
                    <Dialog
                        className="help-dialog"
                        maxWidth="md"
                        fullWidth
                        open={openSetClosingDialog}
                        onClose={() => setOpenSetClosingDialog(false)}
                    >
                        <DialogTitle
                            className="help-dialog-title"
                        >
                            Chiusura di {closingAvailability.referenceMonthDescription}
                        </DialogTitle>
                        <DialogContent
                            sx={{
                                padding: "2vh 3vw",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <TextField
                                className="text-field-fonts"
                                id="month-closing-input-value"
                                sx={{
                                    marginTop: "3vh"
                                }}
                                type="number"
                                label="Importo"
                                defaultValue={!AppData.stats.value.month_closing ? "" : AppData.stats.value.month_closing}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className="button"
                                variant="contained"
                                color="primary"
                                onClick={onSaveClosing}
                            >
                                Salva
                            </Button>
                        </DialogActions>
                    </Dialog>}
            </div>

            {AppData.stats.value.previous_month_closing &&
                <div
                    className="summary-money-in-total-container"
                >
                    <div
                        className="summary-money-in-total-title"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        Chiusura Mensile Attesa
                    </div>
                    <div className="summary-money-in-total-amount">
                        {(AppData.stats.value.previous_month_closing + AppData.stats.value.expected_saving).toLocaleString("it-IT", { style: "currency", currency: "EUR" })}
                    </div>
                </div>
            }

        </div>
    )
}