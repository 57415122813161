import { API_URL } from "./config";

function getColors(token) {
    const headers = {
        "Authorization" : `Bearer ${token}`
    }
    return fetch(`${API_URL}/colors`,
        {
            method: "GET",
            headers : headers
        }
    )
}

export const ColorsAPI = {
    post: {
        
    },
    get: {
        getColors
    },
    put: {
        
    },
    delete: {
        
    }
}