import HelpContentStyledPar from "../HelpContentsStyledPar";
import addRecurrentExpenseForm from "../../../../assets/expenses/add_recurrent_expense/aggiunta_spesa_ricorrente.png"
import addRecurrentExpenseArchive from "../../../../assets/expenses/add_recurrent_expense/archivio_spese_ricorrenti.png"
import addRecurrentMonthlyExpense from "../../../../assets/expenses/add_recurrent_expense/spese_ricorrenti_mensili.png"
import addRecurrentCustomExpense from "../../../../assets/expenses/add_recurrent_expense/spese_ricorrenti_custom.png"

export default function HelpContentExpensesRecurrentExpenses() {
    return (
        <>
            <p>
                Le spese ricorrenti consentono di gestire quelle spese effettuate tramite <b>pagamento rateale</b>. Le spese di questo tipo 
                verranno automaticamente inserite nei periodi previsti. Per aggiungere una spesa ricorrente è sufficiente cliccare lo switch 
                <b> Spese ricorrenti</b>
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={addRecurrentExpenseForm}
                />
            </HelpContentStyledPar>
            <p>
                E' possibile generare un archivio delle spese ricorrenti tramite la sezione <b>Impostazioni</b>. Tramite questa box di input 
                è possibile selezionare un tipo di spesa ricorrente dall'archivio. Questo compilerà automaticamnete i dati di ricorrenza
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={addRecurrentExpenseArchive}
                />
            </HelpContentStyledPar>
            <p>
                I tipi di spese ricorrenti sono due : <b>Mensile</b> e <b>Custom</b>.
                <ul>
                    <li>
                        <b>Mensile: </b> è sufficiente inserire il numero di mesi per la quale la spesa va ripetuta (compreso il mese corrente). 
                    </li>
                    <li>
                        <b>Custom: </b> è necessario definire il numero di ripetizioni (volte) e ogni quanti giorni va ripetura la spesa (giorni)
                    </li>
                </ul>
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images-half"
                    src={addRecurrentMonthlyExpense}
                />
                <img
                    className="help-content-images-half"
                    src={addRecurrentCustomExpense}
                />
            </HelpContentStyledPar>
        </>
    )
}