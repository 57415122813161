import { API_URL } from "./config";
import { RecurrencyPeriodsModel } from "../Models/RecurrencyPeriodsModel";


function createRecurrencyPeriod(token, body = { ...RecurrencyPeriodsModel }) {
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type" : "application/json"
    } 
    return fetch(`${API_URL}/recurrencyperiods`,
        {
            method: "POST",
            headers: headers,
            body : JSON.stringify(body)
        }
    )     
}

function readRecurrencyPeriods(token) {
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type" : "application/json"
    } 
    return fetch(`${API_URL}/recurrencyperiods`,
        {
            method: "GET",
            headers: headers
        }
    )       
}

function deleteRecurrencyPeriod(token, recurrentPeriodId) {
    const headers = {
        "Authorization": `Bearer ${token}`
    } 
    return fetch(`${API_URL}/recurrencyperiods/${recurrentPeriodId}`,
        {
            method: "DELETE",
            headers: headers
        }
    )     
}

function updateRecurrencyPeriod(token, recurrentPeriodId, body) {
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type" : "application/json"
    } 
    return fetch(`${API_URL}/recurrencyperiods/${recurrentPeriodId}`,
        {
            method: "PUT",
            headers: headers,
            body : JSON.stringify(body)
        }
    )      
}


export const RecurrencyPeriodsAPI = {
    post: {
        createRecurrencyPeriod
    },
    get: {
        readRecurrencyPeriods
    },
    update: {
        updateRecurrencyPeriod
    },
    delete: {
        deleteRecurrencyPeriod
    }
}