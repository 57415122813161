import { useCallback, useState } from "react";
import EditMoneyInDialog from "./EditMoneyInDialog";
import { errorNotification, successNotification, toggleLoadingOverlay } from "../../../../../utils";
import { MoneyInAPI } from "../../../../../RemoteAPI/MoneyIn";


export default function MoneyInItem({ item, token, refreshData }) {


    const [openEditDialog, setOpenEditDialog] = useState(false);


    const onMoneyInItemClick = useCallback(() => {
        setOpenEditDialog(true)
    }, [setOpenEditDialog])

    const onEditDialogClose = useCallback(() => {
        setOpenEditDialog(false)
    }, [setOpenEditDialog])


    const onSave = useCallback(async (body = { description: "", amount: 0 }) => {
        toggleLoadingOverlay()
        try {
            const result = await MoneyInAPI.put.updateMoneyIn(item.money_in_id,
                body.description,
                body.amount === "" ? 0 : body.amount,
                token)
            const response = await result.json()
            if (result.status !== 200) {
                errorNotification(response.result.description)
            }
            else {
                successNotification(response.result.description)
                refreshData()
            }
        } catch (error) {
            errorNotification("Errore del server.");
        }
        toggleLoadingOverlay()
    }, [item, token])

    const onDelete = useCallback(async () => {
        toggleLoadingOverlay()
        try {
            const result = await MoneyInAPI.delete.deleteMoneyIn(item.money_in_id, token);

            const response = await result.json()

            if (result.status !== 200) {
                errorNotification(response.result.description)
            }
            else {
                successNotification(response.result.description)
                refreshData()
            }
        } catch (error) {
            errorNotification("Errore del server.");
        }
        toggleLoadingOverlay()
    }, [item, token])

    return (
        <>
            <div onClick={onMoneyInItemClick} className="money-in-item">
                {item.description}: {item.amount.toLocaleString("it-IT", { style: "currency", currency: "EUR" })}
            </div>
            <EditMoneyInDialog
                moneyin={item}
                open={openEditDialog}
                onClose={onEditDialogClose}
                onSave={onSave}
                onDelete={onDelete}
            />
        </>

    )
}