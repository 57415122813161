import { API_URL } from "./config"

const ExpenseBody = {
    description: "",
    expense_category_id: 0,
    amount: 0,
    date: "",
    title: ""
};

function getExpenses(year, month, token, groupedByCategories = true) {
    const headers = {
        "Authorization" : `Bearer ${token}`
    }
    return fetch(`${API_URL}/expenses/year/${year}/month/${month}?groupbycategory=${groupedByCategories ? "true" : "false"}`,
        {
            method: "GET",
            headers : headers
        }
    )
}

function createExpense(token, body = {...ExpenseBody}, month_repetition = 0, days = 0) {
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type" : "application/json"
    }

    let query = "";
    if (month_repetition > 0) {
        query += `months_to_repeat=${month_repetition}&`;
    }

    if (days > 0) {
        query += `month_day_interval=${days}&`;
    }

    return fetch(`${API_URL}/expenses?${query}`,
        {
            method: "POST",
            headers: headers,
            body : JSON.stringify(body)
        }
    )    
}

function deleteExpense(token, expenseId, deleteRecurrents = false) {
    const headers = {
        "Authorization": `Bearer ${token}`
    }

    let query = deleteRecurrents ? "recurrent_propagation=true" : "";


    return fetch(`${API_URL}/expenses/${expenseId}?${query}`, {
        method: "DELETE",
        headers : headers
    })
}

function updateExpense(token, expenseId, body = {...ExpenseBody}, updateRecurrents = false)
{
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type" : "application/json"
    }  

    let query = updateRecurrents ? "recurrent_propagation=true" : "";

    return fetch(`${API_URL}/expenses/${expenseId}?${query}`,
        {
            method: "PUT",
            headers: headers,
            body : JSON.stringify(body)
        }
    )
}

export const ExpensesAPI = {
    get: {
        getExpenses
    },
    post: {
        createExpense 
    },
    put: {
        updateExpense
    },
    delete: {
        deleteExpense
    }
}