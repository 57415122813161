import HelpContentStyledPar from "./HelpContentsStyledPar";
import AddIcon from '@mui/icons-material/Add';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import addExpenseCategory from "../../../assets/settings/aggiungi_categoria_di_spesa.png"
import addExpenseCategoryForm from "../../../assets/settings/aggiungi_categoria_di_spesa_form.png"
import addRecurrentMonthlyExpense from "../../../assets/expenses/add_recurrent_expense/spese_ricorrenti_mensili.png"
import addRecurrentCustomExpense from "../../../assets/expenses/add_recurrent_expense/spese_ricorrenti_custom.png"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function HelpContentSettings() {
    return (
        <>
            <h3>Categorie</h3>
            <p>
                Per poter inserire le spese è necessario definire le proprie <b>Categorie Di Spesa</b>.<br />
                Per inserire una categoria di spesa è necessario cliccare sul pulsante <AddIcon />
            </p>
            <HelpContentStyledPar>
                <img

                    src={addExpenseCategory}
                />
            </HelpContentStyledPar>
            <p>
                E' possibile inserire una categoria di spesa senza budget (è comunque possibile definire i budget mese per mese dalla sezione spese). <br />
                Una volta compilati i dati richiesti è sufficiente cliccare sul tasto <b>Aggiungi</b>.
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={addExpenseCategoryForm}
                />
            </HelpContentStyledPar>
            <p>
                Queste categorie verranno visualizzate nella sezione <b>Uscite</b>
            </p>
            <p>
                E' possibile mettere in ordine le categorie premendo il tasto <FormatListNumberedIcon />. E' sufficiente la pressione dei tasti <ArrowUpwardIcon /> e <ArrowDownwardIcon />
                per portare su o giù una categoria.
            </p>
            <h3>Periodi Ricorrenti</h3>
            <p>
                Da questa sezione si definiscono i periodi ricorrenti selezionabili nella sezione <b>Uscite</b> durante l'inserimento di una spesa ricorrente. <br />
                Per inserire un periodo ricorrente è necessario utilizzare il tasto <AddIcon />.
            </p>
            <div>
                I tipi di spese ricorrenti sono due : <b>Mensile</b> e <b>Custom</b>.
                <ul>
                    <li>
                        <b>Mensile: </b> è sufficiente inserire il numero di mesi per la quale la spesa va ripetuta (compreso il mese corrente). 
                    </li>
                    <li>
                        <b>Custom: </b> è necessario definire il numero di ripetizioni (volte) e ogni quanti giorni va ripetura la spesa (giorni)
                    </li>
                </ul>
            </div>
            <HelpContentStyledPar>
                <img
                    className="help-content-images-half"
                    src={addRecurrentMonthlyExpense}
                />
                <img
                    className="help-content-images-half"
                    src={addRecurrentCustomExpense}
                />
            </HelpContentStyledPar>

        </>
    )
}