import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMemo } from "react";

export default function Errors({ error, resetErrorBoundary }) {
    console.log(error);

    const isServerError = useMemo(() => {
        return error.message === "Failed to fetch"
    }, [error])


    return (
        <div>
            <Dialog
                open={true}
                fullWidth
                maxWidth="lg"
                className="help-dialog"
            >
                <DialogTitle
                    className="help-dialog-title"
                >
                    {isServerError ? "Errore del server" : "Errore dell'applicazione"}
                </DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            textAlign: "center",
                            fontSize: "5vw"
                        }}
                    >
                        {isServerError &&
                            <>
                                <div>
                                    Impossibile stabilire una connessione con il server.
                                </div>
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        marginTop: "2.5vh"
                                    }}
                                >
                                    Ti consigliamo di riprovare più tardi.
                                </div>
                            </>

                        }
                        {!isServerError &&
                            <div>
                                Qualcosa è andato storto, utilizza il pulsante oppure riavvia l'applicazione
                            </div>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => resetErrorBoundary()}
                        className="button"
                    >
                        Riprova
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}