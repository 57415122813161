import HelpContentStyledPar from "../HelpContentsStyledPar";
import expenseButton from "../../../../assets/expenses/add_expense/tasto_spese.png" 
import expenseList from "../../../../assets/expenses/add_expense/lista_spese.png"
import addExpenseForm from "../../../../assets/expenses/add_expense/aggiunta_spesa_form.png"

export default function HelpContentExpensesAddExpense() {
    return (
        <>
            <p>
                Per inserire la spesa nella categoria prescelta è sufficiente cliccare sul tasto <b>Spese</b> della categoria
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={expenseButton}
                />
            </HelpContentStyledPar>
            <p>
                Questa operazione darà accesso alla lista delle spese del mese selezionato in quella categoria. Per aggiungere una spesa è sufficiente 
                cliccare il tsto <b>Aggiungi</b>
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={expenseList}
                />
            </HelpContentStyledPar>
            <p>
                Infine compilare i dati richiesti (la descrizione non è obbligatoria) e cliccare su <b>Salva</b>
            </p>
            <HelpContentStyledPar>
                <img
                    className="help-content-images"
                    src={addExpenseForm}
                />
            </HelpContentStyledPar>
        </>
    )
}