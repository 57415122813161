
import { useCallback, useContext, useState } from "react";
import Header from "./Components/Header/Header";
import "./main.css"
import { AppContext } from "../../Context/AppContext";
import AppDrawer from "./Components/AppDrawer/AppDrawer";
import { MainViews } from "./MainViews";
import MoneyIn from "./Views/MoneyIn/MoneyIn";
import Settings from "./Views/Settings/Settings";
import Expenses from "./Views/Expenses/Expenses";
import Summary from "./Views/Summary/Summary";
import Stats from "./Views/Stats/Stats";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Tutorial from "./Components/Tutorial/Tutorial";

export default function Main({ }) {

    const [openDrawer, setOpenDrawer] = useState(false)

    const [selectedMainView, setSelectedMainView] = useState(MainViews.SUMMARY)

    const AppData = useContext(AppContext)
    
    const onOpenDrawer = useCallback(() => {
        setOpenDrawer(true)
    }, [setOpenDrawer])

    const onCloseDrawer = useCallback(() => {
        setOpenDrawer(false)
    }, [setOpenDrawer])


    console.log(AppData.authentication.value.user.years)
    return (
        <div>
            <Header
                key={"header"}
                selectedMonth={AppData.selectedMonth.value}
                selectedYear={AppData.selectedYear.value}
                years={AppData.authentication.value.user.years}
                setMonth={AppData.selectedMonth.setValue}
                setYear={AppData.selectedYear.setValue}
                toggleDrawer={onOpenDrawer}
                selectedMainView={selectedMainView}
            />
            <AppDrawer
                open={openDrawer}
                onClose={onCloseDrawer}
                selectedMainView={selectedMainView}
                setSelectedMainView={setSelectedMainView}
            />
            {selectedMainView === MainViews.MONEYIN && <MoneyIn />}
            {selectedMainView === MainViews.EXPENSES && <Expenses />}
            {selectedMainView === MainViews.SETTINGS && <Settings />}
            {selectedMainView === MainViews.SUMMARY && <Summary />}
            {selectedMainView === MainViews.STATS && <Stats />}
            <Tutorial
                open={AppData.showTutorial.value}
            />
        </div>
    );
}