import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { Views } from "../../Models/Enums";
import "./noconnection.css"
import { useSpring, a } from "react-spring";

export default function NoConnection({ }) {
    
    const { opacity } = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: {
            duration: 1000
        }
    })

    const { opacity2: opacitySub } = useSpring({
        from: { opacity2: 0 },
        to: { opacity2: 1 },
        config: {
            duration: 1000
        },
        delay: 1000
    })

    return (
        <div className="no-connection-container">
            <a.h1 style={{textAlign : "center", opacity : opacity}}>
                Nessuna connessione a internet
            </a.h1>
            <a.h2 style={{textAlign : "center", opacity : opacitySub}}>
                L'applicazione non può funzionare offline.
            </a.h2>
        </div>
    )
}