import logo from './logo.svg';
import './App.css';
import { useContext } from 'react';
import { AppContext } from './Context/AppContext';
import { Views } from './Models/Enums';
import Login from './Views/Login/Login';
import NoConnection from './Views/NoConnection/NoConnection';
import Main from './Views/Main/Main';
import Splash from './Views/Splash/Splash';

function App() {

  const AppData = useContext(AppContext)
  
  if (!AppData.internetAvailable.value) {
    return <NoConnection />
  }

  if (AppData.selectedView.value === Views.SPLASH) {
    return <Splash />
  }

  if (AppData.selectedView.value === Views.LOGIN) {
    return <Login />
  }

  if (AppData.selectedView.value === Views.MAIN) {
    return <Main />
  }

  return <></>;
}

export default App;
