import { BottomNavigation, BottomNavigationAction} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SegmentIcon from '@mui/icons-material/Segment';
import "./settings.css"
import { useCallback, useState } from "react";
import Categories from "./Components/Categories/Categories";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RecurrencyPeriods from "./Components/RecurrencyPeriods/RecurrencyPeriods";
import Profile from "./Components/Profile/Profile";


const SettingsViews = {
    CATEGORIES: "categories",
    PROFILE: "profile",
    RECURRENCY_PERIODS : "recurrency_periods"
}

export default function Settings({ }) {

    const [bottomNavigationValue, setBottomNavigationValue] = useState(SettingsViews.CATEGORIES)

    const onTabChange = useCallback((event, newValue) => {
        setBottomNavigationValue(newValue)
    }, [setBottomNavigationValue])

    return (
        <div>
            {bottomNavigationValue === SettingsViews.CATEGORIES && <Categories />}
            {bottomNavigationValue === SettingsViews.RECURRENCY_PERIODS && <RecurrencyPeriods />}
            {bottomNavigationValue === SettingsViews.PROFILE && <Profile />}
            <BottomNavigation
                sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}
                showLabels
                value={bottomNavigationValue}
                onChange={onTabChange}
            >
                <BottomNavigationAction label="Categorie" value={SettingsViews.CATEGORIES} icon={<SegmentIcon />} />
                <BottomNavigationAction label="Periodi Ricorrenti" value={SettingsViews.RECURRENCY_PERIODS} icon={<AccessTimeIcon />} />
                <BottomNavigationAction label="Profilo" value={SettingsViews.PROFILE} icon={<AccountCircleIcon />} />
            </BottomNavigation>
        </div>
    )
}