import {useState, useEffect} from 'react'
import './DatePicker.css';

const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];


function DatePicker({value, setValue, isYear = false, years = []}) {
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        function handleOutsideClick(event){
            if((event.target.className.includes && !event.target.className.includes("datepicker")) || (!event.target.className)){
                setShowOptions(false);
            }
        }
        document.addEventListener('click', handleOutsideClick)

        return () => {
            document.removeEventListener("click", handleOutsideClick)
        } 
    },[])


    return (
        <div className='datepicker-container'>
            <div className='datepicker-value' onClick={() => setShowOptions(old => !old)}>{!isYear ? months[value] : value}</div>
            <div className={`datepicker-options ${showOptions ? "is-visible" : "is-invisible"}`}>
                {!isYear && months.map((month, index) => <div key={`m-${month}-${index}`} className={`datepicker-option ${months[value] === month ? "selected" : ""}`} onClick={() => {setValue(index); setShowOptions(false)}}>{month}</div>)}
                {isYear && years.map((year, index) => <div key={`y-${year}-${index}`} className={`datepicker-option ${years[index] === value ? "selected" : ""}`} onClick={() => {setValue(year); setShowOptions(false)}}>{year}</div>)}
            </div>
        </div>
    )
}

export default DatePicker